import React from "react";
import cn from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useFormattedAmount } from "../../../hooks/useFormattedAmount";
import { getColorHex } from "../../../shared/lib/getColorHex";
import { getInitials } from "../../../shared/lib/getInitials";
function PlanUsageDetailsTableBody({ tableData, handlePlanUsageRowClick }) {
  const formatFunction = useFormattedAmount();
  return (
    <tbody>
      {tableData &&
        tableData.map((row, index) => {
          const [hexColor, hexWithOpacity] = getColorHex(row.id);
          return (
            <tr
              key={index}
              className="rounded-[24px] cursor-pointer"
              onClick={() => {
                handlePlanUsageRowClick(row?.user?.id);
              }}
            >
              {Object.keys(row)
                .filter(
                  (item) =>
                    ![
                      "id",
                      "data_used",
                      "minutes_used",
                      "roaming_data_used",
                      "roaming_minutes_used",
                    ].includes(item)
                )
                .map((item, index2) => (
                  <td
                    key={index2}
                    className={cn(
                      "text-secondary !text-[#FFFFFF] px-[16px] py-[20px] text-left !font-medium",
                      {
                        "!px-[10px] w-[25%]": item === "user",
                      }
                    )}
                  >
                    {item === "user" && (
                      <div className="flex items-center gap-4">
                        <div
                          style={{
                            backgroundColor: hexWithOpacity,
                            color: hexColor,
                          }}
                          className="w-[40px] h-[40px] rounded-full flex items-center justify-center"
                        >
                          {getInitials(row[item].name)}
                        </div>
                        <div className="flex flex-col">
                          <span className="text-secondary !text-[#FFFFFF] !text-left !font-medium inline_text">
                            {row[item]?.name}
                          </span>
                          <span className="text-secondary !text-left inline_text">
                            {row[item]?.distinction}
                          </span>
                        </div>
                      </div>
                    )}
                    {item === "total_cost" && formatFunction(row[item])}
                    {item === "dataUsedPlan" && (
                      <p className="mt-1 text-secondary">
                        {row["dataUsedPlan"]}
                      </p>
                    )}
                    {item === "minutesUsedPlan" && (
                      <p className="mt-1 text-secondary">
                        {row["minutesUsedPlan"]}
                      </p>
                    )}
                    {item === "dataRoamingUsed" && (
                      <p className="mt-1 text-secondary">
                        {row["dataRoamingUsed"]}
                      </p>
                    )}
                    {item === "minutesRoamingUsed" && (
                      <p className="mt-1 text-secondary">
                        {row["minutesRoamingUsed"]}
                      </p>
                    )}
                  </td>
                ))}
            </tr>
          );
        })}
      {!tableData && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          {[1, 2, 3, 4, 5].map((el, index) => {
            return (
              <tr key={index}>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
              </tr>
            );
          })}
        </SkeletonTheme>
      )}
    </tbody>
  );
}

export default PlanUsageDetailsTableBody;
