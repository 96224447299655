import React, { useEffect, useRef, useState } from "react";
import Checkbox from "../../../../Checkbox/Checkbox";
import Select from "../../../../Select/select";
import Button from "../../../../Buttons/button";
import TextField from "../../../../TextField/TextField";
import {
  getInitializeServicesData,
  selectCompanyDashboard,
} from "../../../../../redux/slices/companyDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import errorIcon from "../../../../../assets/icons/error-icon.svg";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { current } from "@reduxjs/toolkit";

function ServiceOwner({ formValid, owner_name, setOwner_name, onOwnerChange }) {
  const dispatch = useDispatch();
  const State = useSelector(selectCompanyDashboard);
  const { CategoriesAndUsersData, SetServicesData } = State;
  const setupServices = SetServicesData?.setup_services;
  const [hasWebsite, setHasWebsite] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [selected, setSelected] = useState(null);
  const [searchText, setSearchText] = useState(setupServices?.owner_name);
  const [serviceName, setServiceName] = useState("");
  // const [isTextFieldFocused, setTextFieldFocus] = useState(false);
  const [isTextMatch, setIsTextMatch] = useState(false);
  // const [category, setCategory] = useState("");
  // for keybord choise
  const [activeIndex, setActiveIndex] = useState(-1);
  const ulItemRefs = useRef([]);

  useEffect(() => {
    dispatch(getInitializeServicesData());
  }, []);

  const handleUserNameInput = (field, value, e) => {
    if (value.trim() === "") {
      setSearchText("");
      setSearchResults([]);
    } else {
      setSearchText(value);

      const filteredUsers = CategoriesAndUsersData?.company_users.filter(
        (user) => {
          const searchTerm = value.toLowerCase();
          return (
            user.name.toLowerCase().includes(searchTerm) ||
            user.email.toLowerCase().includes(searchTerm) ||
            user.id.toString().includes(searchTerm)
          );
        }
      );
      setSearchResults(filteredUsers);
    }
  };

  const handleResultClick = (result) => {
    handleUserNameInput("name", result.name);
    // dispatch(updateSubmissionData({ field: 'service_id', value: result.id }));
    // setSearchText(result.name);
    setSearchResults([]);
    setSelected(result);
    setIsTextMatch(true);
    setOwner_name(result.name);
  };

  const setButtonRef = (element, index) => {
    ulItemRefs.current = ulItemRefs.current.filter(Boolean);
    ulItemRefs.current[index] = element;
  };

  const handleKeyDown = (e, result) => {
    if (!searchResults.length || !ulItemRefs.current.length) {
      return;
    }

    if (e.key === "ArrowDown") {
      const nextIndex = (activeIndex + 1) % ulItemRefs.current.length;
      setActiveIndex(nextIndex);
      ulItemRefs.current[nextIndex]?.focus();

      return;
    }

    if (e.key === "ArrowUp") {
      const prevIndex =
        (activeIndex - 1 + ulItemRefs.current.length) %
        ulItemRefs.current.length;

      setActiveIndex(prevIndex);

      ulItemRefs.current[prevIndex]?.focus();

      return;
    }

    if (e.key === "Enter" && result?.name) {
      handleResultClick(result);
    }
  };

  //   const handleTextFieldFocus = () => {
  //     setTextFieldFocus(true);
  //   };
  //
  //   const handleTextFieldBlur = () => {
  //     setTimeout(() => {
  //       setTextFieldFocus(false);
  //     }, 100);
  //   };

  return (
    <div className="flex flex-col gap-[24px] pr-[16px] pb-[16px] addServiceForm">
      <div className="flex flex-start flex-col text-left">
        <div className="text-primary !text-[24px] !leading-[36px]">
          Service owner
        </div>
        <div className="flex flex-start step_heading">
          Assign a service owner to maintain this service. An email will be
          sent.
        </div>
      </div>

      <div className="flex flex-col">
        <div className="relative">
          <label className="flex flex-end  justify-end items-center text-primary !font-semibold mb-[8px]">
            {!formValid && (
              <span>
                <img
                  src={errorIcon}
                  alt="Error Icon"
                  className="w-[24px] h-[24px]"
                />
              </span>
            )}
          </label>
          <TextField
            placeholder={"Name, email, or employee ID"}
            value={searchText}
            onChange={(e) => handleUserNameInput("name", e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            // onFocus={handleTextFieldFocus}
            // onBlur={handleTextFieldBlur}
          />
          <p className="text-secondary text-left">
            Owners can be changed at any time from a services “service members”
            list.
          </p>

          {searchResults.length > 0 && (
            <ul
              tabIndex={0}
              className="z-10 absolute mt-1 max-h-40 scrollbar-hide w-full overflow-auto bg-[#293043] p-2 rounded-[16px]"
            >
              {searchResults.map((result, i) => (
                <li
                  tabIndex={i}
                  ref={(el) => setButtonRef(el, i)}
                  key={result.id}
                  onKeyDown={(e) => handleKeyDown(e, result)}
                  onClick={() => handleResultClick(result)}
                  className={`relative cursor-default py-2 pl-10 pr-4 text-left transition-colors duration-300 hover:bg-[#363D50] rounded-[12px] border border-solid border-transparent active:border-[#FFD74A] mb-2 last:mb-0 ${
                    selected === result ? "bg-[#363D50] !border-[#FFD74A]" : ""
                  }`}
                >
                  <div className="flex flex-col">
                    <span className="text-primary">{result.name}</span>
                    <span className="text-secondary">{result.distinction}</span>
                  </div>

                  {selected === result && (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default ServiceOwner;
