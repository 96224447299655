import React from 'react'
import StatusBar from "../statusBar/StatusBar";
import { useDispatch, useSelector } from 'react-redux';
import {
	notifyMultipleServiceOwner,
  selectCompanyDashboard,
} from "../../redux/slices/companyDashboardSlice";

const DataScoreCard = ({manageServicesData, service_ids}) => {
	const dispatch = useDispatch();
  const percentage = manageServicesData.data_score_percentage
  const services = manageServicesData.services
  const totalUserServiceDetails = manageServicesData.total_contracts
  const completeUserServiceDetails = manageServicesData.services_with_user_details_count
  const missing = totalUserServiceDetails - completeUserServiceDetails
  const reportDay = manageServicesData.monthly_report_day
  const lastReportMonth = manageServicesData.last_reportable_month
  const activeBillingPeriod = manageServicesData.active_billing_period_id;
  const lastReportableBillingPeriod = manageServicesData.last_reportable_billing_period_id;
  const lastReportPublishingTime = new Date(manageServicesData.last_report_time);
	const currentDate = new Date();
	const currentDay = currentDate.getDate();
	const currentMonth = currentDate.getMonth();
	const currentYear = currentDate.getFullYear();

  const calculateRemainingDays = (reportDay, currentDay) => {
    let remainingDays = reportDay - currentDay;

    if (remainingDays < 0) {
      const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
      remainingDays = (daysInCurrentMonth - currentDay) + Number(reportDay);
    }

    return remainingDays;
  };

  const remainingDays = calculateRemainingDays(reportDay, currentDay);

  const shouldReportPublish = activeBillingPeriod === lastReportableBillingPeriod;
	const reportDate = new Date(currentYear, currentMonth, reportDay);
	const manualPublicationRequired = currentDate >= reportDate;

  const handleClick = () => {
		dispatch(notifyMultipleServiceOwner(service_ids))
  }

  return (
    <div className='flex flex-col bg-[#293043] rounded-[32px] px-[16px] py-[16px]'>
    	<div className="!flex !justify-between !items-center">
    		<div>
    			<div className="flex items-center gap-2 w-[600px]">
    				<StatusBar percentage={percentage} />
    				<div className="flex flex-col">
							<div className="text-primary text-left">Data score ({lastReportMonth?.slice(0,3)})</div>
    					<div className=" flex items-center text-secondary text-left gap-1">
							  {shouldReportPublish ? (
								    <span>Reports have been published for this month.</span>
								  ) : manualPublicationRequired ? (
								    <span>A manual publishing is required to display {lastReportMonth}'s data to employees.</span>
								  ) : (
								    <>
								      <span>
								        <svg
								          width="14"
								          height="14"
								          viewBox="0 0 14 14"
								          fill="none"
								          xmlns="http://www.w3.org/2000/svg"
								        >
								          <path fill="#ED4650" />
								        </svg>
								      </span>
								      <span>{`${remainingDays} days until report generation`}</span>
								    </>
								  )}
							</div>
    				</div>
    			</div>
    		</div>
    		<div>
	    		<div className="flex items-center gap-4">
	    			{ manageServicesData.data_score_percentage === 100 ? null :
		    			<div className="flex flex-col items-end">
		    				<div className="text-primary !text-[14px]">
									<span className="!text-[#ED4650] !text-[18px]">{missing}</span>
										/ {totalUserServiceDetails}
		    				</div>
		    				<div className="text-secondary">missing data instances</div>
		    			</div>
	    			}
	    			<div>
							<button onClick={()=>handleClick()}>
	    					<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="52" height="52" rx="16" fill="#363D50"/>
									<path fill-rule="evenodd" clip-rule="evenodd" d="M19.3681 16.6489C17.9535 15.978 16.3514 17.1207 16.5244 18.6768L17.2638 25.3332H26.0005C26.3687 25.3332 26.6671 25.6316 26.6671 25.9998C26.6671 26.368 26.3687 26.6665 26.0005 26.6665H17.2638L16.5244 33.3241C16.3515 34.8795 17.953 36.022 19.3683 35.352L35.2937 27.8067C36.8185 27.0848 36.8187 24.9149 35.2939 24.193L19.3681 16.6489ZM15.9964 25.9998L15.1992 18.8242C14.9109 16.231 17.5806 14.3257 19.9392 15.444M19.9392 15.444L35.8644 22.9879C38.4061 24.1914 38.4062 27.8081 35.8646 29.0116L19.9392 36.557C17.5813 37.6734 14.9109 35.7707 15.1992 33.1769L15.9964 25.9998" fill="#FFD74A"/>
								</svg>
	    				</button>
	    			</div>
	    		</div>
	    	</div>
    	</div>
    </div>
  )
}

export default DataScoreCard