import { useDispatch, useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Image from "../../../assets/images/model_Image.svg";
import Button from "../../Buttons/button";
import { useEffect } from "react";
import {
  getUsageReportRequest,
  selectPersonalDashboard,
} from "../../../redux/slices/personalDashboardSlice";

function ServiceRequestModel({ setstate, setPopup, id, setId }) {
  const State = useSelector(selectPersonalDashboard);
  const { usageReportData } = State;
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getUsageReportRequest(id.id));
    }
  }, []);

  useEffect(() => {
    if (usageReportData) {
      setId((prevId) => ({
        ...prevId,
        serviceId: usageReportData?.service?.id,
        userId: usageReportData?.user?.id,
      }));
    }
  }, [usageReportData]);

  return (
    <div className="transform rounded-[24px] bg-[#363D50] transition-all flex">
      {usageReportData?.service?.name ? (
        <div className="flex flex-col gap-[10px] md:w-[500px] pt-[24px] pr-[24px] pb-[32px] pl-[24px]">
          <div className="flex justify-between md:h-[46px]">
            <div className="flex flex-start items-center gap-[16px]">
              <img
                className="w-[40px] h-[40px] card_img rounded-lg"
                src={usageReportData?.service?.image_url}
                alt="service-img"
              />
              <div className="flex flex-col">
                <span className="text-primary !text-[18px]">
                  Make a service request
                </span>
                <span className="text-secondary !text-left">
                  {usageReportData?.service?.name}
                </span>
              </div>
            </div>
            <div className="flex h-[40px] justify-end">
              <svg
                className="cursor-pointer"
                onClick={() => {
                  setPopup(false);
                }}
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className="flex flex-col pt-[16px] gap-[24px]">
            <div className="flex flex-start">
              <span className="text-primary text-left !text-[24px] !leading-[36px]">
                Choose what you'd like to do with this service:
              </span>
            </div>

            <div className="flex flex-col gap-[16px] py-[5px]">
              <Button
                onClick={() => {
                  setstate(7);
                }}
                className="flex flex-col card-animate gap-[16px] px-[16px] py-[24px] border-[2px] border-[#41485C] rounded-[24px] w-full borderOutline"
              >
                <span className="text-primary text-left !text-[14px]">
                  Suggest service change
                </span>
                <span className="text-secondary text-left">
                  Make suggestions to management for changes to this service, so
                  it might better suit you, your team, or your company’s needs.
                </span>
              </Button>

              <Button
                onClick={() => {
                  setstate(6);
                }}
                className="flex flex-col card-animate gap-[16px] px-[16px] py-[24px] border-[2px] border-[#41485C] rounded-[24px] w-full borderOutline"
              >
                <span className="text-primary text-left !text-[14px]">
                  Request to be opted-out of this service
                </span>
                <span className="text-secondary text-left">
                  If you don't think this service is working, request to
                  opt-out.
                </span>
              </Button>
              {/* Request to be OPT-IN of this service setstate(5)*/}
            </div>

            <div className="flex flex-wrap items-center">
              <span className="text-secondary text-left">
                Thinking of a good replacement service?
              </span>
              <Button
                onClick={() => {
                  setstate(1);
                }}
                className="!font-normal"
                underline={true}
                secondary={true}
              >
                Submit a new service suggestion!
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-[10px] md:w-[500px] pt-[24px] pr-[24px] pb-[32px] pl-[24px]">
          <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
            <div className="flex justify-between md:h-[46px]">
              <div className="flex flex-start items-center gap-[16px]">
                <Skeleton className="!w-[40px] !h-[40px] !rounded-lg" />
                <div className="flex flex-col">
                  <Skeleton className="!w-[112px]" />
                  <Skeleton className="!w-[112px]" />
                </div>
              </div>
              <div className="flex h-[40px] justify-end">
                <svg
                  className="cursor-pointer"
                  onClick={() => {
                    setPopup(false);
                  }}
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div className="flex flex-col pt-[16px] gap-[24px]">
              <Skeleton className="!w-full !h-[69px] !rounded-lg" />

              <div className="flex flex-col gap-[16px] py-[5px]">
                <Skeleton className="!w-full !h-[154px] !rounded-[24px]" />
                <Skeleton className="!w-full !h-[130px] !rounded-[24px]" />
              </div>

              <Skeleton className="!w-full !h-[50px] !rounded-lg" />
            </div>
          </SkeletonTheme>
        </div>
      )}
    </div>
  );
}

export default ServiceRequestModel;
