import React from "react";
import RadioButton from "../../../../RadioButton/RadioButton";
import TextField from "../../../../TextField/TextField";

function First({ setReason, reason, setReasonType, reasonType }) {
  const handleRadioButtonChange = (value) => {
    setReasonType(value);
  };
  return (
    <div className="flex flex-col md:w-[536px] md:h-[404px] pr-[16px] pb-[16px] gap-[24px]">
      <div className="flex flex-start">
        <span className="text-primary text-left !text-[18px] !leading-[27px]">
          Why are you suggesting to opt-out of this service?
        </span>
      </div>
      <div className="">
        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={reasonType === "not_use_this_service"}
            onChange={() => handleRadioButtonChange("not_use_this_service")}
          />
          <div className="text-left w-full">
            <span
              className="text-secondary !text-[18px] cursor-pointer w-full block"
              onClick={() => handleRadioButtonChange("not_use_this_service")}
            >
              I don’t use this service
            </span>
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={reasonType === "expense_this_service"}
            onChange={() => handleRadioButtonChange("expense_this_service")}
          />
          <div className="text-left w-full">
            <span
              className="text-secondary !text-[18px] cursor-pointer w-full block"
              onClick={() => handleRadioButtonChange("expense_this_service")}
            >
              I personally expense this service for work
            </span>
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={reasonType === "service_not_provide"}
            onChange={() => handleRadioButtonChange("service_not_provide")}
          />
          <div className="text-left w-full">
            <span
              className="text-secondary !text-[18px] cursor-pointer w-full block"
              onClick={() => handleRadioButtonChange("service_not_provide")}
            >
              This service doesn’t provide what I need
            </span>
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={reasonType === "other"}
            onChange={() => handleRadioButtonChange("other")}
          />
          <div className="flex flex-col w-full">
            <span
              className="text-secondary text-left !text-[18px] mb-[4px] cursor-pointer w-full block"
              onClick={() => handleRadioButtonChange("other")}
            >
              Other
            </span>
            {reasonType === "other" && (
              <TextField
                onChange={(e) => setReason(e.target.value)}
                placeholder={"Please explain"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default First;
