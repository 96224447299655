import React from "react";
import { useFormattedAmount } from "../../../../hooks/useFormattedAmount";

function PrintingUsageCard({printingData, active_billing_month}) {
  const formattedAmount = useFormattedAmount();
  return (
    <>
      <div className="flex flex-col bg-[#363D50] rounded-[24px] pb-4 gap-[8px] max-w-[100%] h-[fit-content]">
        <div className="flex items-center pt-[24px] px-[24px] pb-[16px] gap-[16px] w-full">
          <div className="flex justify-center items-center bg-[#242B3E] p-2 rounded-[16px] w-[48px] h-[48px]">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.4075 6.11682C17.762 6.82099 17.2444 7.71932 16.8999 8.66634H21.4113C21.9297 8.65388 22.3388 8.51779 22.6441 8.29498C22.9604 8.06414 23.2152 7.70411 23.3538 7.16657C23.6719 5.93285 22.8697 4.66634 21.3327 4.66634C20.2375 4.66634 19.2373 5.21158 18.4075 6.11682ZM15.1642 8.66634C14.7975 7.71423 14.2804 6.81631 13.6444 6.1139C12.8247 5.20854 11.8476 4.66634 10.7555 4.66634C9.21853 4.66634 8.41634 5.93285 8.73442 7.16657C8.87302 7.70411 9.12785 8.06414 9.44414 8.29498C9.74942 8.51779 10.1585 8.65388 10.6769 8.66634H15.1642ZM16.026 7.31046C16.3941 6.546 16.8643 5.82706 17.4246 5.21586C18.4281 4.1211 19.7612 3.33301 21.3327 3.33301C23.7957 3.33301 25.1667 5.47542 24.6449 7.49945C24.5315 7.93932 24.3549 8.33174 24.1187 8.66844C25.9044 8.73131 27.3327 10.1987 27.3327 11.9997V14.6663C27.3327 16.0332 26.51 17.2079 25.3327 17.7223V22.6663C25.3327 24.5073 23.8403 25.9997 21.9993 25.9997H9.99935C8.1584 25.9997 6.66602 24.5073 6.66602 22.6663V17.7223C5.48875 17.2079 4.66602 16.0332 4.66602 14.6663V11.9997C4.66602 10.1692 6.14154 8.68326 7.9681 8.66648C7.73259 8.33021 7.5565 7.93847 7.44331 7.49945C6.92147 5.47542 8.29254 3.33301 10.7555 3.33301C12.3302 3.33301 13.6416 4.12414 14.6328 5.21902C15.1866 5.83065 15.6528 6.54893 16.026 7.31046ZM10.6627 9.99967H7.99935C6.89478 9.99967 5.99935 10.8951 5.99935 11.9997V14.6663C5.99935 15.7709 6.89478 16.6663 7.99935 16.6663H15.3327V9.99967H10.7555V10.0006C10.7245 10.0006 10.6935 10.0003 10.6627 9.99967ZM21.3327 10.0006V9.99967H16.666V16.6663H23.9993C25.1039 16.6663 25.9993 15.7709 25.9993 14.6663V11.9997C25.9993 10.8951 25.1039 9.99967 23.9993 9.99967H21.4255C21.3947 10.0003 21.3637 10.0006 21.3327 10.0006ZM16.666 17.9997H23.9993V22.6663C23.9993 23.7709 23.1039 24.6663 21.9993 24.6663H16.666V17.9997ZM15.3327 17.9997V24.6663H9.99935C8.89478 24.6663 7.99935 23.7709 7.99935 22.6663V17.9997H15.3327Z"
                fill="white"
              />
            </svg>
          </div>

          <div className="flex flex-start flex-col gap-[2px]">
            <div className="text-primary !text-[16px] !font-bold !leading-[24px]">
              Monthly plan usage ({active_billing_month})
            </div>
          </div>
        </div>
        <div className="px-8">
          <div className="h-px bg-[#434A5D]"></div>
          { printingData?.map((item, index) =>(
            <div key={index} className="mt-6">
              <div className="flex items-center gap-1">
                <p className="text-white text-sm font-normal">{item.name}</p>
              </div>
              <div className="flex items-center gap-7 pt-6 pb-8">
                <div className="h-[91px]">
                  <p className="!text-[32px] !font-bold !leading-[48px] text-white tracking-[0.02em]">
                    {formattedAmount(item.bw_pages_cost)}
                  </p>
                  <p className="text-secondary">{item.bw_pages_count} Black & white prints</p>
                </div>
                <div className="w-px h-[32px] bg-[#596073]"></div>
				<div className="h-[91px]">
                  <p className="!text-[32px] !font-bold !leading-[48px] text-white tracking-[0.02em]">
                    {formattedAmount(item.color_pages_cost)}
                  </p>
                  <p className="text-secondary">{item.color_pages_count} color prints</p>
                </div>
                <div className="w-px h-[32px] bg-[#596073]"></div>
                <div className="h-[91px]">
                  <p className="!text-[32px] !font-bold !leading-[48px] text-white tracking-[0.02em]">
                    {formattedAmount(item.license_fee)}
                  </p>
                  <p className="text-secondary">License fee</p>
                </div>
                <div className="w-px h-[32px] bg-[#596073]"></div>
                <div className="h-[91px]">
                  <p className="!text-[32px] !font-bold !leading-[48px] text-white tracking-[0.02em]">
                    {formattedAmount(item.total_cost)}
                  </p>
                  <p className="text-secondary">
                    Total cost
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default PrintingUsageCard;
