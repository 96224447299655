import React, { useState, useEffect } from "react";
import Tab from "../../sidebar/components/tabs";
import Tabs from "./tabs/tabs";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, selectUser } from "../../../redux/slices/userSlice";
import {
  getSetupServicesData,
  selectCompanyDashboard,
  getServiceTemplate,
  getManageServicesDataScore,
} from "../../../redux/slices/companyDashboardSlice";

function ManageServiceModel({ setstate, setPopup, id, activeTab }) {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(1);
  const dispatch = useDispatch();
  const State = useSelector(selectCompanyDashboard);
  const { CategoriesAndUsersData, SetServicesData } = State;
  const setupServices = SetServicesData?.setup_services;

  useEffect(() => {
    if (activeTab) {
      setTab(activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    dispatch(getSetupServicesData(id));
  }, [id]);

  useEffect(()=>{
    dispatch(getServiceTemplate(id))
  },[id])

  useEffect(() => {
    dispatch(getManageServicesDataScore());
  }, [ ]);

  return (
    <div className="line-head flex flex-col w-[1300px] h-[800px]  rounded-[24px] bg-[#363D50]">
      <div className="pt-0 flex flex-col customize_setting_model">
        <div className="flex justify-between items-center model_header">
          <div className="flex flex-start items-center gap-[16px]">
            <div className="icon-wrapp">
              {setupServices?.category === "Expenses" ? (
                <div className="p-1 rounded-lg bg-black">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.6665 8.00163C4.6665 6.1611 6.15846 4.66699 7.99984 4.66699H23.9998C25.8412 4.66699 27.3332 6.1611 27.3332 8.00163V26.7531C27.3332 27.8154 26.1486 28.4563 25.2602 27.864L23.1079 26.4292C22.8664 26.2681 22.5484 26.2819 22.3217 26.4633L20.5826 27.8546C19.8521 28.4389 18.8142 28.4389 18.0838 27.8546L16.4163 26.5206C16.1728 26.3258 15.8269 26.3258 15.5834 26.5206L13.9159 27.8546C13.1855 28.4389 12.1476 28.4389 11.4171 27.8546L9.67802 26.4633C9.45132 26.2819 9.13332 26.2681 8.89176 26.4292L6.73944 27.864C5.85112 28.4563 4.6665 27.8154 4.6665 26.7531V8.00163ZM7.99984 6.00033C6.89569 6.00033 5.99984 6.89664 5.99984 8.00163V26.7531L8.15216 25.3198C8.87684 24.8366 9.83085 24.878 10.5109 25.4221L12.25 26.8134C12.4935 27.0082 12.8395 27.0082 13.083 26.8134L14.7504 25.4794C15.4809 24.8951 16.5188 24.8951 17.2492 25.4794L18.9167 26.8134C19.1602 27.0082 19.5062 27.0082 19.7496 26.8134L21.4887 25.4221C22.1688 24.878 23.1228 24.8366 23.8475 25.3198L25.9992 26.7542L25.9998 8.00163C25.9998 6.89664 25.104 6.00033 23.9998 6.00033H7.99984ZM9.99984 12.0003C9.99984 11.6321 10.2983 11.3337 10.6665 11.3337H16.6665C17.0347 11.3337 17.3332 11.6321 17.3332 12.0003C17.3332 12.3685 17.0347 12.667 16.6665 12.667H10.6665C10.2983 12.667 9.99984 12.3685 9.99984 12.0003ZM19.9998 12.0003C19.9998 11.6321 20.2983 11.3337 20.6665 11.3337H21.3332C21.7014 11.3337 21.9998 11.6321 21.9998 12.0003C21.9998 12.3685 21.7014 12.667 21.3332 12.667H20.6665C20.2983 12.667 19.9998 12.3685 19.9998 12.0003ZM9.99984 17.3337C9.99984 16.9655 10.2983 16.667 10.6665 16.667H21.3332C21.7014 16.667 21.9998 16.9655 21.9998 17.3337C21.9998 17.7018 21.7014 18.0003 21.3332 18.0003H10.6665C10.2983 18.0003 9.99984 17.7018 9.99984 17.3337Z"
                      fill="white"
                    />
                  </svg>
                </div>
              ) : (
                <img
                  className="w-[40px] h-[40px] card_img rounded-lg"
                  src={setupServices?.image}
                  alt="service-img"
                />
              )}
            </div>
            <div className="flex flex-col">
              <span>
                <b>Manage Services</b>
              </span>
              <div className="flex flex-col">
                <span className="text-secondary lineHeight !text-[#FFFFFF] !text-left !font-medium">
                  {setupServices?.name}
                </span>
              </div>
            </div>
          </div>
          <div className="flex h-[40px] justify-end items-center">
            <svg
              className="cursor-pointer"
              onClick={() => {
                setPopup(false);
              }}
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <div className={`animation flex flex-row settings_content_wrapper`}>
          <div
            className={`animation !pl-[24px] !pr-[0px] !pt-[12px] w-[280px] setting_sidebar`}
          >
            <div className="w-full bg-[#363D50]">
              <Tab
                childrenStyle={{
                  marginLeft: "20px",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#A5AEBC",
                  letterSpacing: "0.02em",
                  lineHeight: "21px",
                }}
                active={tab === 1}
                onClick={() => setTab(1)}
              >
                About
              </Tab>
              <Tab
                childrenStyle={{
                  marginLeft: "20px",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#A5AEBC",
                  letterSpacing: "0.02em",
                  lineHeight: "21px",
                }}
                active={tab === 2}
                onClick={() => setTab(2)}
              >
                Members
              </Tab>
              <Tab
                childrenStyle={{
                  marginLeft: "20px",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#A5AEBC",
                  letterSpacing: "0.02em",
                  lineHeight: "21px",
                }}
                active={tab === 3}
                onClick={() => setTab(3)}
              >
                Manage data
              </Tab>
              {/* <Tab
                childrenStyle={{marginLeft: '20px',fontSize: '14px', fontWeight: 700, color: '#A5AEBC', letterSpacing: '0.02em', lineHeight: '21px'}}
                active={tab === 4}
                onClick={()=> setTab(4)}
              >
                Thryft settings
              </Tab> */}
            </div>
          </div>
          <div
            className={`${
              tab === 3
                ? "manage-right_model_details"
                : "right_model_details pr-[24px]"
            }`}
          >
            <Tabs Tab={tab} id={id} setModel={setPopup} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageServiceModel;
