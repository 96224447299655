export const heading = ["User", "Total cost", "Data used (MB)", "Minutes used", "Roaming data used (MB)", "Roaming minutes used"];

export const tableData = [
  {
    id: 0,
    user: "Kevin So",
    total_cost: "267",
    data_used: "130",
    minutes_used: "98",
    roaming_used: "50"
  },
  {
    id: 1,
    user: "James Kel",
    total_cost: "425",
    data_used: "111",
    minutes_used: "108",
    roaming_used: "8"
  },
  {
    id: 2,
    user: "Mari Dae",
    total_cost: "114",
    data_used: "180",
    minutes_used: "30",
    roaming_used: "23"
  },
  {
    id: 3,
    user: "Jeken Tos",
    total_cost: "314",
    data_used: "130",
    minutes_used: "98",
    roaming_used: "50"
  },
  {
    id: 4,
    user: "Tester",
    total_cost: "211",
    data_used: "78",
    minutes_used: "24",
    roaming_used: "24"
  },
  {
    id: 5,
    user: "william Ru",
    total_cost: "247",
    data_used: "114",
    minutes_used: "12",
    roaming_used: "78"
  },
  {
    id: 6,
    user: "Tomas Eki",
    total_cost: "145",
    data_used: "75",
    minutes_used: "101",
    roaming_used: "100"
  },
  {
    id: 7,
    user: "Roke Jam",
    total_cost: "257",
    data_used: "24",
    minutes_used: "45",
    roaming_used: "78"
  },
  {
    id: 8,
    user: "Roman Mas",
    total_cost: "178",
    data_used: "12",
    minutes_used: "84",
    roaming_used: "42"
  },
  {
    id: 9,
    user: "Bryan Ale",
    total_cost: "275",
    data_used: "65",
    minutes_used: "100",
    roaming_used: "45"
  },
];
