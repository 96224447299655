import React from "react";
import RadioButton from "../../../../RadioButton/RadioButton";
import TextField from "../../../../TextField/TextField";

function First({ setReason, reason, setReasonType, reasonType }) {
  const handleRadioButtonChange = (value) => {
    setReasonType(value);
  };
  return (
    <div className="flex flex-col md:w-[536px] md:h-[404px] pr-[16px] pb-[16px] gap-[24px]">
      <div className="flex flex-start">
        <span className="text-primary text-left !text-[24px] !leading-[36px]">
          Why are you suggesting to opt-in to this service?
        </span>
      </div>
      <div className="">
        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={reasonType === "try_service"}
            onChange={() => handleRadioButtonChange("try_service")}
          />
          <div className="text-left w-full">
            <span
              className="text-secondary !text-[18px] cursor-pointer w-full block"
              onClick={() => handleRadioButtonChange("try_service")}
            >
              I want to try this service
            </span>
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={reasonType === "better_than_other"}
            onChange={() => handleRadioButtonChange("better_than_other")}
          />
          <div className="text-left w-full">
            <span
              className="text-secondary !text-[18px] cursor-pointer w-full block"
              onClick={() => handleRadioButtonChange("better_than_other")}
            >
              I like it better than another tool I use
            </span>
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={reasonType === "more_efficient"}
            onChange={() => handleRadioButtonChange("more_efficient")}
          />
          <div className="text-left w-full">
            <span
              className="text-secondary !text-[18px] cursor-pointer w-full block"
              onClick={() => handleRadioButtonChange("more_efficient")}
            >
              It will make my work more efficient
            </span>
          </div>
        </div>
        {/* onChange={() => handleRadioButtonChange('Other')} */}
        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={reasonType === "other"}
            onChange={() => handleRadioButtonChange("other")}
          />
          <div className="flex flex-col w-full">
            <span
              className="text-secondary text-left !text-[18px] mb-[4px] cursor-pointer w-full block"
              onClick={() => handleRadioButtonChange("other")}
            >
              Other
            </span>
            <TextField
              onChange={(e) => setReason(e.target.value)}
              placeholder={"Please explain"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default First;
