import { createSlice } from "@reduxjs/toolkit";
import { fetchWithToken, postWithToken, patchWithToken } from "../../config/apiRequests";
import { toast } from "react-toastify";
import SuccessIcon from "../../assets/icons/success-check.svg";
// import { updateRequestDetails } from './companyDashboardSlice'

export const personalDashboardSlice = createSlice({
  name: "personalDashboard",
  initialState: {
    teamName: '',
    teamId: '',
    isLoading: false,
    success: false,
    error: null,
    message: "",
    endPoint: "",
    historyData: {},
    servicesData: {
      services: [1,2,3,4,5,6, 7,8,9]
    },
    employeesData: undefined,
    managerName: '',
    totalTeamCost:undefined,
    totalSubmissions:'',
    totalFeedbacks:'',
    totalRequests:'',
    highCostServices: undefined,
    employeesmanagerData: {},
    submissionsData: {},
    requestsData: {},
    feedbacksData: {},
    feedbacksDetailsData:{},
    updatefeedbackData: {},
    submissionDetailsData: {},
    updaterequestsData: {},
    usageReportData: {},
    printingUsageReportData: {},
    teamPrintingUsageReportData: {},
    employeeCostHistory: {},
    requestDetailsData: {},
    categoriesData:{},
    allServicesData:{},
    updateStatusSubmission: {},
    updateSubmissionsData: {
      expensed_by_name: null,
      service_to_replace_id: null,
      submission_details: ""
    },
    employeeDetailData: undefined,
    savingOpportunityData: {},
    similarServiceData: {},
    createdSubmission: false,
    userExpenses: [],
    userExpensesComment: {},
    expensesCommentData: {},
    teamExpenses: [],
  },

  reducers: {
    updateHistory: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/costs_history";
        state.message = "History fetched successfully";
        state.historyData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/costs_history";
        state.isLoading = false;
      }
    },
    updateUsageReportHistory: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/services/:service_id/costs_history";
        state.message = "History fetched successfully";
        state.serviceHistoryData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/services/:service_id/costs_history";
        state.isLoading = false;
      }
    },
    updateEmployees: (state, action) => {
      if (action.payload) {
        if (action.payload.status === 500) {
          toast.error(action.payload.error);
        } else {
          state.success = true;
          state.endPoint = "api/v1/personal/employees";
          state.message = "Employees fetched successfully";
          state.employeesData = action.payload.employees;
          state.managerName = action.payload.manager_name;
          state.totalTeamCost = action.payload.total_team_cost
          state.averageTeamCost = action.payload.average_team_cost
          state.totalSubmissions = action.payload.total_submissions
          state.totalFeedbacks = action.payload.total_feedbacks
          state.totalRequests = action.payload.total_requests
          state.highCostServices = action.payload.high_cost_service
        }        
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/employees";
        state.isLoading = false;
      }
    },

    updateServices: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/services";
        state.message = "Services fetched successfully";
        state.servicesData = action.payload; 
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/services";
        state.isLoading = false;
      }
    },
    createRequestComment: (state, action) => {
      const { status, id, ...payload } = action.payload;
      state.endPoint = "api/v1/personal/requests/{id}/comment";
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Request comment created successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.requestCommentData = {
            ...state.requestCommentData,
            [id]: payload,
          };
          break;
        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;
        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;
        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;
        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;
        default:
          state.success = false;
          state.message = "Request comment creation failed";
          toast.error(state.message);
      }
    },
    createSubmissionComment: (state, action) => {
      const { status, id, ...payload } = action.payload;
      state.endPoint = "api/v1/personal/submissions/{id}/comment";
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Request comment created successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.reCommentData = {
            ...state.requestCommentData,
            [id]: payload,
          };
          break;
        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;
        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;
        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;
        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;
        default:
          state.success = false;
          state.message = "Request comment creation failed";
          toast.error(state.message);
      }
    },

    createExpensesComment: (state, action) => {
      const { status, id, ...payload } = action.payload;
      state.endPoint = "/api/v1/expenses/${id}/comments";
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Expenses comment created successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.expensesCommentData = {
            ...state.expensesCommentData,
            [id]: payload,
          };
          break;
        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;
        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;
        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;
        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;
        default:
          state.success = false;
          state.message = "Request comment creation failed";
          toast.error(state.message);
      }
    },

    updaterequestData: (state, action) => {
      const { status, id, ...payload } = action.payload;

      state.endPoint = "api/v1/personal/requests/:id";
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Request updated successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.requestDetailsData = {
            ...state.requestDetailsData,
            [id]: payload,
          };
          break;
        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;
        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;
        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;
        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;
        default:
          state.success = false;
          state.message = "Update Request failed";
          toast.error(state.message);
      }
    },

    updateSubmissions: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/submissions";
        state.message = "Submissions fetched successfully";
        state.submissionsData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/submissions";
        state.isLoading = false;
      }
    },
    updateSubmissionDetails: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/submissions/{id}";
        state.message = "Submission details fetched successfully";
        state.submissionDetailsData = action.payload; 
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/submissions/{id}";
        state.isLoading = false;
      }
    },
    updateRequests: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/requests";
        state.message = "Requests fetched successfully";
        state.requestsData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/requests";
        state.isLoading = false;
      }
    },

    getUserExpensesComment: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.userExpensesComment = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.isLoading = false;
      }
    },
    savingOpportunity: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.savingOpportunityData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.isLoading = false;
      }
    },
    similarServices: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.similarServiceData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.isLoading = false;
      }
    },
    updateEmployeeCostHistory: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/employees/:emp_id/costs_history";
        state.message = "Employee's cost history fetched successfully";
        state.employeeCostHistory = action.payload; 
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/employees/:emp_id/costs_history";
        state.isLoading = false;
      }
    },

    updateUserExpenses: (state, action) => {
      if(action.payload) {
        state.userExpenses = action.payload;
        state.success = true;
        state.endPoint = "api/v1/users/:user_id/personal_expenses";
        state.message = "User expenses fetched successfully!";
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/users/:user_id/personal_expenses";
        state.isLoading = false;
      }
    },

    updateCategories: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/categories";
        state.message = "Requests fetched successfully";
        state.categoriesData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/categories";
        state.isLoading = false;
      }
    },

    updateAllServices: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/services/all_services";
        state.message = "Requests fetched successfully";
        state.allServicesData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/services/all_services";
        state.isLoading = false;
      }
    },
    createServiceRequest: (state, action) => {
      const { status, id, ...payload } = action.payload;

      state.endPoint = "api/v1/personal/requests";
      state.isLoading = false;
      state.success = false;
      state.updaterequestsData= {}
      switch (status) {
        case 201:
          state.success = true;
          state.message = "Request created successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.error = false;
          state.updaterequestsData = action.payload
          break;
        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;
        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;
        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;
        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;
        default:
          state.success = false;
          state.error = true;
          state.message = "Request Failed";
          toast.error(state.message);
      }
    },
    createFeedback: (state, action) => {
      const { status, id, ...payload } = action.payload;

      state.endPoint = "api/v1/personal/feedbacks";
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Feedback created successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.updatefeedbackData = {
            ...state.updatefeedbackData,
            [id]: payload,
          };
          break;
        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;
        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;
        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;
        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;
        default:
          state.success = false;
          state.message = "Feedback creation failed";
          toast.error(state.message);
      }
    },

    updateUsageReport: (state, action) => {
      state.error = null;
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/services/{id}/usage_report";
        state.message = "Usage report fetched successfully";
        state.usageReportData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/services/{id}/usage_report";
        state.isLoading = false;
      }
    },

    printingUsageReport: (state, action) => {
      state.error = null;
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/services/{id}/printing_usage_report";
        state.message = "Printing Usage report fetched successfully";
        state.printingUsageReportData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/services/{id}/printing_usage_report";
        state.isLoading = false;
      }
    },

    teamPrintingUsageReport: (state, action) => {
      state.error = null;
      if (action.payload) {
        state.success = true;
        state.endPoint = "/api/v1/personal/users/${userId}/team_printing_usage_report?service_id=${serviceId}";
        state.message = " Printing Usage report fetched successfully";
        state.teamPrintingUsageReportData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "/api/v1/personal/users/${userId}/team_printing_usage_report?service_id=${serviceId}";
        state.isLoading = false;
      }
    },


    updateRequestDetails: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/requests/{id}";
        state.message = "Request details fetched successfully";
        state.requestDetailsData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/requests/{id}";
        state.isLoading = false;
      }
    },
    updateStatus: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/personal/requests/{id}"
        state.message = 'Status updated successfully'
        state.isLoading = false
      }else {
        state.success = false;
        state.endPoint = "api/v1/personal/requests/{id}";
        state.isLoading = false;
      }
    },
    updateSubmissionsstatus: (state, action) =>{
      if(action.payload){
        state.success = true
        state.endPoint = "api/v1/personal/submissions/{id}"
        state.message = 'Status updated successfully'
        state.isLoading = false
        state.updateStatusSubmission = action.payload.data
      }else {
        state.success = false;
        state.endPoint = "api/v1/personal/submissions/{id}";
        state.isLoading = false;
      }
    },
    createSubmission: (state, action) => {
      const { status, id, ...payload } = action.payload;

      state.endPoint = "api/v1/personal/submissions";
      state.isLoading = false;

      switch (status) {
        case 201:
          state.success = true;
          state.message = "Submission created successfully";
          toast.success(state.message, {
            icon: ({theme, type}) =>  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]"><img src={SuccessIcon} alt="success icon"/></div>
          });
          state.submissionsData = {
            ...state.submissionsData,
            [id]: payload,
          };

          state.updateSubmissionsData = {
            expensed_by_name: null,
            service_to_replace_id: null,
            submission_details: ""
          }
          state.createdSubmission = true;
          break;
        case 400:
          state.success = false;
          state.message = "Bad Request: Invalid data provided";
          toast.error(state.message);
          break;
        case 401:
          state.success = false;
          state.message = "Unauthorized: Please authenticate";
          toast.error(state.message);
          break;
        case 403:
          state.success = false;
          state.message =
            "Forbidden: You do not have permission to perform this action";
          toast.error(state.message);
          break;
        case 500:
          state.success = false;
          state.message = "Internal Server Error: Something went wrong";
          toast.error(state.message);
          break;
        default:
          state.success = false;
          state.message = "Submission creation failed";
          toast.error(state.message);
      }
    },

    updateFeedbacks: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/feedbacks";
        state.message = "Feedbacks fetched successfully";
        state.feedbacksData = action.payload;
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/feedbacks";
        state.isLoading = false;
      }
    },
    updateFeedbackDetails: (state, action) =>{
      if(action.payload){
        state.success = true;
        state.endPoint = "api/v1/personal/feedbacks/:id";
        state.message = "Details Feedback fetched successfully"
        state.isLoading = false;
        state.feedbacksDetailsData = action.payload
      }else{
        state.success = false
        state.endPoint = "api/v1/personal/feedbacks/:id"
        state.isLoading = false
      }
    },
    updateEmployeeDetail: (state, action) => {
      if (action.payload) {
        state.success = true;
        state.endPoint = "api/v1/personal/employees/:id";
        state.message = "Employee details fetched successfully";
        state.employeeDetailData = action.payload; 
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/personal/employees/:id";
        state.isLoading = false;
      }
    },
    updatedPersonalRequests: (state, action) => {
      if (state.requestsData) {
        state.requestsData.requests = action.payload;
      }
    },
    updatedServices: (state, action) => {
      state.servicesData.services = action.payload;
    },
    updatedTeam: (state, action) => {
      state.employeesData = action.payload;
    },
    updateSubmissionData: (state, action) => {
      state.updateSubmissionsData[action.payload.field] = action.payload.value;
    },

    updateSubmissionSortList: (state, action) => {
      state.submissionsData.submissions = action.payload;
    },

    setCreatedSubmissionFalse: (state, action) => {
      state.createdSubmission = false;
    },

    resetUpdateSubmissionData: (state, action) => {
      state.updateSubmissionsData = {
        expensed_by_name: null,
        service_to_replace_id: null,
        submission_details: ""
      }
    },

    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setEmpty: (state) => {
      state.updaterequestsData = {}
    },

    setEmptyUsageReport: (state) => {
      state.usageReportData = {}
    },

    setEmptyEmployeeData: (state) => {
      state.employeeDetailData = undefined;
    },

    setEmptyEmployees: (state) => {
      state.employeesData = undefined;
        state.managerName = '';
        state.totalTeamCost = '';
        state.averageTeamCost = undefined;
        state.totalSubmissions = '';
        state.totalFeedbacks = '';
        state.totalRequests = '';
        state.highCostServices = undefined;
    },

    updateTeamName: (state, action) => {
      state.teamName = action.payload
    },

    setTeamId: (state, action) => {
      state.teamId = action.payload
    },

    updateTeamExpenses: (state, action) => {
      if(action.payload) {
        state.teamExpenses = action.payload;
        state.success = true;
        state.endPoint = "api/v1/user/:user_id/team_expenses";
        state.message = "Team expenses fetched successfully!";
        state.isLoading = false;
      } else {
        state.success = false;
        state.endPoint = "api/v1/user/:user_id/team_expenses";
        state.isLoading = false;
      }
    }
  },
});

export const {
  updateHistory,
  updaterequestData,
  updateSubmissionData,
  updateSubmissionSortList,
  updateFeedbackDetails,
  updateEmployees,
  updateServices,
  updateRequests,
  updateFeedbacks,
  updateSubmissionDetails,
  createServiceRequest,
  createFeedback,
  updateUsageReport,
  updateUsageReportHistory,
  updateRequestDetails,
  updateStatus,
  updateSubmissionsstatus,
  createRequestComment,
  createSubmissionComment,
  createExpensesComment,
  updatedTeam,
  updatedServices,
  updatedPersonalRequests,
  updateCategories,
  updateAllServices,
  updateEmployeeCostHistory,
  updateUserExpenses,
  createSubmission,
  updateSubmissions,
  updateEmployeeDetail,
  setLoading,
  setEmpty,
  setEmptyUsageReport,
  setEmptyEmployeeData,
  setEmptyEmployees,
  updateTeamName,
  savingOpportunity,
  similarServices,
  setCreatedSubmissionFalse,
  getUserExpensesComment,
  setTeamId,
  updateTeamExpenses,
  printingUsageReport,
  teamPrintingUsageReport,
} = personalDashboardSlice.actions;

export const selectPersonalDashboard = (state) => state.personalDashboard;

export const getEmployeesRequest = (userId) => (dispatch) => {
  fetchWithToken(`/api/v1/personal/users/${userId}/employees`, dispatch, updateEmployees);
};

export const getCategories = () => (dispatch) => {
  fetchWithToken("api/v1/categories", dispatch, updateCategories);
};

export const getAllServices = () => (dispatch) => {
  fetchWithToken("api/v1/personal/services/all_services", dispatch, updateAllServices);
};

export const createRequestComments = (id, requestData) => (dispatch) => {
  postWithToken(`api/v1/personal/requests/${id}/comment`, requestData, dispatch, createRequestComment);
};

export const createSubmissionComments = (id, requestData) => (dispatch) => {
  postWithToken(`api/v1/personal/submissions/${id}/comment`, requestData, dispatch, createSubmissionComment);
};

export const createExpensesComments = (id, requestData) => (dispatch) => {
  postWithToken(`/api/v1/expenses/${id}/comments`, requestData, dispatch, createExpensesComment);
};

export const getUserExpensesComments = (id, userId) => (dispatch) => {
  fetchWithToken(`/api/v1/expenses/${id}/comments?user_id=${userId}`, dispatch, getUserExpensesComment);
};


export const updateRequest = (id, requestData) => (dispatch) => {
  postWithToken(`api/v1/personal/requests/:${id}`, requestData, dispatch, updaterequestData);
};

export const getSubmissionDetailsRequest = (id, userId) => (dispatch) => {
  fetchWithToken(`api/v1/personal/submissions/${id}?user_id=${userId}`, dispatch, updateSubmissionDetails);
};

export const getHistoryRequest = () => (dispatch) => {
  fetchWithToken("api/v1/personal/costs_history", dispatch, updateHistory);
};

export const getServicesRequest = () => (dispatch) => {
  fetchWithToken("api/v1/personal/services", dispatch, updateServices);
};

export const createServicesRequest = (requestData) => (dispatch) => {
  postWithToken("api/v1/personal/requests", requestData, dispatch, createServiceRequest);
};

export const getUsageReportRequest = (serviceId) => (dispatch) => {
  fetchWithToken(`api/v1/personal/services/${serviceId}/usage_report`, dispatch, updateUsageReport);
};

export const getPrintingUsageReport = (serviceId) => (dispatch) => {
  fetchWithToken(`api/v1/personal/services/${serviceId}/usage_report_printing`, dispatch, printingUsageReport);
};

export const getTeamPrintingUsageReport = (serviceId, userId) => (dispatch) => {
  fetchWithToken(`/api/v1/personal/users/${userId}/team_printing_usage_report?service_id=${serviceId}`, dispatch, teamPrintingUsageReport);
};


export const getUsageReportHistory = (service_id) => (dispatch) => {
  fetchWithToken(`api/v1/personal/services/${service_id}/costs_history`, dispatch, updateUsageReportHistory);
};

export const getFeedBackDetails = (serviceID, feedbackID) => (dispatch) =>{
  fetchWithToken(`/api/v1/personal/services/${serviceID}/usage_report?feedback_id=${feedbackID}`, dispatch, updateFeedbackDetails);
}

export const getcompanyFeedBackDetails = (serviceID, feedbackID) => (dispatch) =>{
  fetchWithToken(`/api/v1/company/services/${serviceID}/usage_report?feedback_id=${feedbackID}`, dispatch, updateFeedbackDetails);
}

export const getFeedBackDetailsCompany = (id,feedBackId) => (dispatch) => {
  fetchWithToken(`/api/v1/company/employees/${id}/feedbacks?feedback_id=${feedBackId}`, dispatch, updateFeedbackDetails);
}
export const createFeedbackRequest = (requestData) => (dispatch) => {
  postWithToken("api/v1/personal/feedbacks", requestData, dispatch, createFeedback);
};

export const getRequestDetails = (id) => (dispatch) => {
  fetchWithToken(`api/v1/personal/requests/${id}`, dispatch, updateRequestDetails);
};

export const statusUpdateRequest = (id, requestData ) => (dispatch) => {
  patchWithToken(`api/v1/personal/requests/${id}`, requestData, dispatch, updateStatus);
};

export const statusUpdateSubmission = (id, submissionData ) => (dispatch) => {
  patchWithToken(`api/v1/personal/submissions/${id}`, submissionData, dispatch, updateSubmissionsstatus);
};

export const getSubmissionsRequest = () => (dispatch) => {
  fetchWithToken("api/v1/personal/submissions", dispatch, updateSubmissions);
};

export const getRequestsPage = (sort_by = 'request_type', sort_direction = 'asc') => (dispatch) => {
  fetchWithToken(`api/v1/personal/requests?sort_by=${sort_by}&sort_direction=${sort_direction}`, dispatch, updateRequests);
};

export const getRequestsPagewithoutParams = () => (dispatch) => {
  fetchWithToken("api/v1/personal/requests", dispatch, updateRequests);
};

export const getSavingOpportunity = (id, queryType) => (dispatch) => {
  fetchWithToken(`api/v1/savings_opportunities?${queryType}=${id}`, dispatch, savingOpportunity);
};

// export const getsimilarServices = (id) => (dispatch) => {
//   fetchWithToken(`api/v1/?emp_id=${id}`, dispatch, similarServices);
// };

export const createSubmissionRequest = (requestData) => (dispatch) => {
  postWithToken("api/v1/personal/submissions", requestData, dispatch, createSubmission);
};

export const getFeedbacksRequest = () => (dispatch) => {
  fetchWithToken("api/v1/personal/feedbacks", dispatch, updateFeedbacks);
};

export const getEmployeeDetailRequest = (id) => (dispatch) => {
  fetchWithToken(`api/v1/personal/employees/${id}`, dispatch, updateEmployeeDetail);
};

export const getEmployeeCostHistoryRequest = (emp_id) => (dispatch) => {
  fetchWithToken(`api/v1/employees/${emp_id}/costs_history`, dispatch, updateEmployeeCostHistory);
};

export const getUserExpenses = (emp_id) => (dispatch) => {
  fetchWithToken(`api/v1/user/${emp_id}/personal_expenses`, dispatch, updateUserExpenses);
};

export const getTeamExpenses = (emp_id) => (dispatch) => {
  fetchWithToken(`api/v1/user/${emp_id}/team_expenses`, dispatch, updateTeamExpenses);
};

export default personalDashboardSlice.reducer;
