import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useFormattedAmount } from "../../../../../hooks/useFormattedAmount";

function PillCards({ totalMonthlyCost, averageMonthlyCost }) {
  const formatFunction = useFormattedAmount();
  return (
    <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
      <div className="grid grid-cols-1 md:grid-cols-2 justify-center xl:justify-between items-center gap-[32px]">
        <div className="flex gap-[12px] w-full max-w-[100%] h-[100px] p-[24px] rounded-[32px] bg-[#363D50]">
          <div className="flex justify-center flex-col gap-[6px] w-[203px] h-[51px]">
            {totalMonthlyCost !== undefined && (
              <span className="text-primary !text-[18px]">
                {formatFunction(totalMonthlyCost)}
              </span>
            )}
            {totalMonthlyCost === undefined && (
              <Skeleton className="!w-[132px]" />
            )}
            <span className="text-secondary !leading-[21px]">
              {" "}
              Total monthly cost
            </span>
          </div>
        </div>
        <div className="flex gap-[12px] w-full max-w-[100%] h-[100px] p-[24px] rounded-[32px] bg-[#363D50]">
          <div className="flex justify-center flex-col gap-[6px] w-[203px] h-[51px]">
            {averageMonthlyCost !== undefined && (
              <span className="text-primary !text-[18px]">
                {formatFunction(averageMonthlyCost)}
              </span>
            )}
            {averageMonthlyCost === undefined && (
              <Skeleton className="!w-[132px]" />
            )}
            <span className="text-secondary !leading-[21px]">
              {" "}
              Average employee cost
            </span>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default PillCards;
