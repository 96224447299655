import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function StartDate({start_date, setStart_date, placeholder, error, setError, setToggleIcon }) {

  const handleStartDateChange = (date) => {
    setStart_date(date)
    setToggleIcon(false)
    setError((prevError) => ({ ...prevError, start_date: false }));
  }

  const handleFocuse = () => {
    setToggleIcon(true)
  }

  return (
    <DatePicker
      selected={start_date}
      onChange={(date) => handleStartDateChange(date)}
      dateFormat="MMM dd yyyy"
      placeholderText={ placeholder ? placeholder : "" }
      className={`focus:outline-none focus:ring-0 focus:shadow-none focus:!border-white ${error?.start_date ? "!border-red-500" : ""}`}      
      popperClassName="date-custome-picker"
      onFocus={handleFocuse}
    />
  );
};

export default StartDate;