import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Button from "../../../../components/Buttons/button";
import { useFormattedAmount } from "../../../../hooks/useFormattedAmount";
import { getSlicedCardData } from "../../../evaluator/company-dashboard/home/lib/getSlicedCardData";
import { useNavigate } from "react-router-dom";
import { setEmptyUsageReport } from "../../../../redux/slices/companyDashboardSlice";

function HighServicesCostCards({ highCostServices, user_id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleEmptyUsageReport = () => {
    dispatch(setEmptyUsageReport());
  };

  const formatFunction = useFormattedAmount();
  return (
    <>
      {highCostServices && (
        <div className="flex flex-col bg-[#363D50] pb-[16px] rounded-[24px]  max-w-[100%]">
          <div className="flex flex-col p-[24px]">
            <div className="flex items-center gap-[16px]">
              <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.4075 6.11719C17.762 6.82136 17.2444 7.71968 16.8999 8.66671H21.4113C21.9297 8.65425 22.3388 8.51816 22.6441 8.29535C22.9604 8.0645 23.2152 7.70448 23.3538 7.16693C23.6719 5.93322 22.8697 4.66671 21.3327 4.66671C20.2375 4.66671 19.2373 5.21195 18.4075 6.11719ZM15.1642 8.66671C14.7975 7.71459 14.2804 6.81668 13.6444 6.11427C12.8247 5.20891 11.8476 4.66671 10.7555 4.66671C9.21853 4.66671 8.41634 5.93322 8.73442 7.16693C8.87302 7.70448 9.12785 8.0645 9.44414 8.29535C9.74942 8.51816 10.1585 8.65425 10.6769 8.66671H15.1642ZM16.026 7.31082C16.3941 6.54637 16.8643 5.82743 17.4246 5.21622C18.4281 4.12147 19.7612 3.33337 21.3327 3.33337C23.7957 3.33337 25.1667 5.47579 24.6449 7.49981C24.5315 7.93969 24.3549 8.3321 24.1187 8.66881C25.9044 8.73167 27.3327 10.1991 27.3327 12V14.6667C27.3327 16.0336 26.51 17.2083 25.3327 17.7227V22.6667C25.3327 24.5077 23.8403 26 21.9993 26H9.99935C8.1584 26 6.66602 24.5077 6.66602 22.6667V17.7227C5.48875 17.2083 4.66602 16.0336 4.66602 14.6667V12C4.66602 10.1695 6.14154 8.68362 7.9681 8.66685C7.73259 8.33058 7.5565 7.93884 7.44331 7.49981C6.92147 5.47579 8.29254 3.33337 10.7555 3.33337C12.3302 3.33337 13.6416 4.12451 14.6328 5.21938C15.1866 5.83101 15.6528 6.5493 16.026 7.31082ZM10.6627 10H7.99935C6.89478 10 5.99935 10.8955 5.99935 12V14.6667C5.99935 15.7713 6.89478 16.6667 7.99935 16.6667H15.3327V10H10.7555V10.001C10.7245 10.001 10.6935 10.0007 10.6627 10ZM21.3327 10.001V10H16.666V16.6667H23.9993C25.1039 16.6667 25.9993 15.7713 25.9993 14.6667V12C25.9993 10.8955 25.1039 10 23.9993 10H21.4255C21.3947 10.0007 21.3637 10.001 21.3327 10.001ZM16.666 18H23.9993V22.6667C23.9993 23.7713 23.1039 24.6667 21.9993 24.6667H16.666V18ZM15.3327 18V24.6667H9.99935C8.89478 24.6667 7.99935 23.7713 7.99935 22.6667V18H15.3327Z"
                    fill="white"
                  />
                </svg>
              </div>

              <div className="flex flex-start flex-col gap-[2px]">
                <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                  Highest Cost Services
                </div>
                <span className="text-secondary !text-[14px] !leading-[20px]">
                  Services with the highest price tag
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col px-[16px]">
            {/* <div className='px-[8px]'><div className='w-[100%] h-[1px] bg-[#41485C]' /></div> */}

            <div className="flex flex-col w-[100%] h-[360px] py-[3px] px-[3px]">
              {getSlicedCardData(highCostServices)?.map((service) => (
                <Link
                  key={service.id}
                  to="#"
                  // to={`/personal_dashboard/team_services/usage_report/${service.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleEmptyUsageReport();
                    navigate(
                      `/personal_dashboard/team_services/usage_report/${service.id}`,
                      {
                        state: {
                          userId: user_id,
                        },
                      }
                    );
                  }}
                  className="flex justify-between items-center card-animate w-[100%] cursor-pointer py-[25px] px-[5px] border-t-[1px] border-[#41485C] hover:rounded-[8px]"
                >
                  <div className="flex gap-[16px] items-center">
                    {service?.name !== "Personal Expenses" && (
                      <img
                        src={service?.image_url}
                        alt="company-logo"
                        className="w-[40px] h-[40px] rounded-[8px] card_img"
                      />
                    )}
                    {service?.name === "Personal Expenses" && (
                      <div className="w-[40px] h-[40px] flex justify-center items-center rounded-[8px] bg-[#242B3E]">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.6665 8.00163C4.6665 6.1611 6.15846 4.66699 7.99984 4.66699H23.9998C25.8412 4.66699 27.3332 6.1611 27.3332 8.00163V26.7531C27.3332 27.8154 26.1486 28.4563 25.2602 27.864L23.1079 26.4292C22.8664 26.2681 22.5484 26.2819 22.3217 26.4633L20.5826 27.8546C19.8521 28.4389 18.8142 28.4389 18.0838 27.8546L16.4163 26.5206C16.1728 26.3258 15.8269 26.3258 15.5834 26.5206L13.9159 27.8546C13.1855 28.4389 12.1476 28.4389 11.4171 27.8546L9.67802 26.4633C9.45132 26.2819 9.13332 26.2681 8.89176 26.4292L6.73944 27.864C5.85112 28.4563 4.6665 27.8154 4.6665 26.7531V8.00163ZM7.99984 6.00033C6.89569 6.00033 5.99984 6.89664 5.99984 8.00163V26.7531L8.15216 25.3198C8.87684 24.8366 9.83085 24.878 10.5109 25.4221L12.25 26.8134C12.4935 27.0082 12.8395 27.0082 13.083 26.8134L14.7504 25.4794C15.4809 24.8951 16.5188 24.8951 17.2492 25.4794L18.9167 26.8134C19.1602 27.0082 19.5062 27.0082 19.7496 26.8134L21.4887 25.4221C22.1688 24.878 23.1228 24.8366 23.8475 25.3198L25.9992 26.7542L25.9998 8.00163C25.9998 6.89664 25.104 6.00033 23.9998 6.00033H7.99984ZM9.99984 12.0003C9.99984 11.6321 10.2983 11.3337 10.6665 11.3337H16.6665C17.0347 11.3337 17.3332 11.6321 17.3332 12.0003C17.3332 12.3685 17.0347 12.667 16.6665 12.667H10.6665C10.2983 12.667 9.99984 12.3685 9.99984 12.0003ZM19.9998 12.0003C19.9998 11.6321 20.2983 11.3337 20.6665 11.3337H21.3332C21.7014 11.3337 21.9998 11.6321 21.9998 12.0003C21.9998 12.3685 21.7014 12.667 21.3332 12.667H20.6665C20.2983 12.667 19.9998 12.3685 19.9998 12.0003ZM9.99984 17.3337C9.99984 16.9655 10.2983 16.667 10.6665 16.667H21.3332C21.7014 16.667 21.9998 16.9655 21.9998 17.3337C21.9998 17.7018 21.7014 18.0003 21.3332 18.0003H10.6665C10.2983 18.0003 9.99984 17.7018 9.99984 17.3337Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    )}
                    <div className="flex flex-col">
                      <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-left">
                        {service?.name}
                      </span>
                      <span className="text-secondary !leading-[20px] !text-left">
                        {service?.categories}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-right">
                      {formatFunction(service?.service_cost)}
                    </span>
                    <span className="text-secondary !leading-[20px] !text-right">
                      Cost per month
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <div className="flex justify-center items-center h-[49px]">
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/personal_dashboard/team_services`, {
                  state: {
                    userId: user_id,
                  },
                });
              }}
            >
              <Button
                className={cn({
                  invisible: !highCostServices?.length,
                })}
                secondary
                hoverColor
              >
                View all
              </Button>
            </Link>
          </div>
        </div>
      )}
      {!highCostServices && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          <div className="flex flex-col gap-4 bg-[#363D50] p-[24px] rounded-[24px] max-w-[100%]">
            <Skeleton className="!w-[250px] !h-[40px]" />
            <Skeleton count={5} className="!h-5" />
          </div>
        </SkeletonTheme>
      )}
    </>
  );
}

export default HighServicesCostCards;
