import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";

export const useFormattedAmount = () => {
  const { userDetails } = useSelector(selectUser);

  const formatFunction = (amount) => {
    if (amount === null) return "";
    const currencySymbol = userDetails?.currency_symbol || "";
    let currency = currencySymbol;
    switch (currencySymbol) {
      case "USD ":
        currency = "$";
        break;
      case "EUR ":
        currency = "€";
        break;
      default:
        break;
    }
    const formattedAmount = amount
      ? (+parseFloat(amount).toFixed(2)).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "0";

    return `${currency}${formattedAmount}`;
  };
  return formatFunction;
};
