import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Transition } from "@headlessui/react";
import Button from "../../../../../components/Buttons/button";
import menuDot from "../../../../../assets/images/menuDot.png";
import Search from "../../../../../assets/images/search.png";
import {
  getServiceMembers,
  setServiceMemberCreatedFalse,
  selectCompanyDashboard,
  setServiceMemberUpdatedFalse,
} from "../../../../../redux/slices/companyDashboardSlice";
import { getInitials } from "../../../../../shared/lib/getInitials";
import { getColorHex } from "../../../../../shared/lib/getColorHex";
import BasicModal from "../../../../models/models";
import Unicon from "../../../../../assets/images/arrow-down-circle.svg";
import cn from "classnames";

const Members = ({ id }) => {
  const dispatch = useDispatch();
  const companyDashboardState = useSelector(selectCompanyDashboard);
  const { serviceMemberData, serviceMemberCreated, serviceMemberUpdated } =
    companyDashboardState;
  const serviceMembers = serviceMemberData.service_members;

  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [userId, setUserId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedUsers, setSortedUsers] = useState([]);
  const [sortOrderName, setSortOrderName] = useState("asc");
  const [sortOrderType, setSortOrderType] = useState("asc");
  const [sortOrderAdded, setSortOrderAdded] = useState("asc");
  const [sortOrderLastActive, setSortOrderLastActive] = useState("asc");
  const [userType, setUserType] = useState("");

  useEffect(() => {
    dispatch(getServiceMembers(id));
  }, [id, serviceMemberCreated, serviceMemberUpdated]);

  useEffect(() => {
    if (serviceMembers) {
      setSortedUsers([...serviceMembers]);
    }
  }, [serviceMembers]);

  const handleMenuClick = (userId, userTpye) => {
    setUserId(userId);
    setUserType(userTpye);
  };

  const handleChangeMemberType = () => {
    setPopup(true);
    setState(42);
    dispatch(setServiceMemberUpdatedFalse());
  };

  const handleRemoveMember = () => {
    setPopup(true);
    setState(39);
    dispatch(setServiceMemberUpdatedFalse());
  };

  const handleAddServiceMember = () => {
    setPopup(true);
    setState(40);
    // setUserId(userId);
  };

  const handleMemberType = () => {
    setPopup(true);
    setState(41);
    // setUserId(userId);
  };

  const handleMember = () => {
    const sorted = [...serviceMembers].sort((a, b) => {
      if (sortOrderName === "asc") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    setSortOrderName(sortOrderName === "asc" ? "desc" : "asc");
    setSortedUsers(sorted);
  };

  const handleType = () => {
    const sorted = [...serviceMembers].sort((a, b) => {
      const typeA = a.type || "";
      const typeB = b.type || "";

      if (sortOrderType === "asc") {
        return typeA.localeCompare(typeB);
      } else {
        return typeB.localeCompare(typeA);
      }
    });
    setSortOrderType(sortOrderType === "asc" ? "desc" : "asc");
    setSortedUsers(sorted);
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  };

  const handleAdded = () => {
    const sorted = [...serviceMembers].sort((a, b) => {
      const dateA = parseDate(a.added);
      const dateB = parseDate(b.added);
      if (sortOrderAdded === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setSortOrderAdded(sortOrderAdded === "asc" ? "desc" : "asc");
    setSortedUsers(sorted);
  };

  const handleLastActive = () => {
    const sorted = [...serviceMembers].sort((a, b) => {
      const dateA = parseDate(a.last_active);
      const dateB = parseDate(b.last_active);
      if (sortOrderLastActive === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setSortOrderLastActive(sortOrderLastActive === "asc" ? "desc" : "asc");
    setSortedUsers(sorted);
  };

  const displayMembers = sortedUsers.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="manage-service-wrapper mt-2 ManageServicesBox">
      <div className="flex flex-col md:flex-row md:justify-between gap-[24px] md:items-start">
        <div className="flex flex-row justify-start gap-[24px] items-center">
          <div className="flex flex-col">
            <span className="text-primary !text-[24px] !leading-[36px] text-left">
              Service members
            </span>
            <span className="text-secondary !text-[14px] !leading-[21px] text-left">
              <span>
                Service members have access to this service’s dashboard and more
                depending on their{" "}
              </span>
              <span>
                <a
                  href="#"
                  className="memberLink"
                  onClick={() => handleMemberType()}
                >
                  member type.
                </a>
              </span>
            </span>
          </div>
        </div>

        <Button
          outlined={true}
          hoverColor={true}
          secondary={true}
          secondaryHoverOutlined={true}
          onClick={() => {
            handleAddServiceMember();
            dispatch(setServiceMemberCreatedFalse());
          }}
        >
          + Add Member
        </Button>
      </div>

      <div className="searchBox mt-4">
        <img src={Search} alt="icon" className="searchIcon" />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search member"
          className="searchInput !not-italic"
        />
      </div>

      <div className="rounded-t-xl !mt-0 pb-[20px] w-[100%] scrollbar-hide scrollable_data">
        <div className="overflow-x-auto scrollbar-hide custom_scroll_hide">
          <table className="sticky top-0">
            <thead className="table-head sticky top-0 bg-[#363D50]">
              <tr>
                <th
                  className={`text-left text-secondary border-[#434A5D] py-[12px] pl-0 pr-[16px] w-[550px]`}
                >
                  <Button
                    className="sorting_btn_wrapper"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleMember}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Member
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrderName === "desc",
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th
                  className={`text-left text-secondary border-[#434A5D] py-[12px] pl-0 pr-[16px]`}
                >
                  <Button
                    className="sorting_btn_wrapper"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleType}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Type
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrderType === "desc",
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th
                  className={`text-left text-secondary border-[#434A5D] py-[12px] pl-0 pr-[16px]`}
                >
                  <Button
                    className="sorting_btn_wrapper"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleAdded}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Added
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrderAdded === "desc",
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th
                  className={`text-left text-secondary border-[#434A5D] py-[12px] pl-0 pr-[16px]`}
                >
                  <Button
                    className="sorting_btn_wrapper"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleLastActive}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Last active
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrderLastActive === "desc",
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th
                  className={`text-left text-secondary border-[#434A5D] py-[12px] pl-0 pr-[16px]`}
                >
                  &nbsp;
                </th>
              </tr>
            </thead>

            <tbody>
              {displayMembers?.map((user) => {
                const [hexColor, hexWithOpacity] = getColorHex(user.id);
                return (
                  <tr key={user.id} className="cursor-pointer">
                    <td className="text-left text-secondary !text-[#FFFFFF] py-[16px] pl-2 pr-[16px]">
                      <div className="flex flex-col md:flex-row items-center gap-[10px]">
                        <div
                          style={{
                            backgroundColor: hexWithOpacity,
                            color: hexColor,
                          }}
                          className="w-[40px] h-[40px] rounded-full flex items-center justify-center"
                        >
                          {getInitials(user.name)}
                        </div>
                        <div className="flex flex-col">
                          <span className="text-secondary lineHeight !text-[#FFFFFF] !text-left !font-medium">
                            {user.name}
                          </span>
                          <span className="text-secondary lineHeight !text-left">
                            {user.distinction}
                          </span>
                        </div>
                      </div>
                    </td>

                    <td className="text-left text-secondary !text-[#FFFFFF] py-[16px] px-[16px] font_12px">
                      {user.type}
                    </td>
                    <td className="text-left text-secondary !text-[#FFFFFF] py-[16px] px-[16px] !font-medium font_12px">
                      {user.added}
                    </td>
                    <td className="text-left lineHeight text-secondary !text-[#FFFFFF] py-[16px] px-[16px] !font-medium">
                      {user.last_active}
                    </td>
                    <td className="text-left text-secondary !text-[#FFFFFF] py-[16px] px-[16px] !font-medium">
                      {/* <div
                      className="menuDot"
                      onClick={() => handleMenuClick(user.id, user.type)}
                    >
                      <img src={menuDot} alt="icon" />
                    </div> */}
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button
                            className="px-2"
                            onClick={() => handleMenuClick(user.id, user.type)}
                          >
                            <img src={menuDot} className="z-10" alt="icon" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute z-20 right-[-16px] mt-2 py-2 w-[332px] bg-[#293043] rounded-3xl origin-top-right">
                            <div className="px-2">
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    className={`${
                                      active ? "bg-[#363D50]" : ""
                                    } cursor-pointer rounded-2xl px-4 py-2 border border-solid border-transparent transition-all duration-150 ease-linear active:!border-[#FFD74A]`}
                                    onClick={() => handleChangeMemberType()}
                                  >
                                    <p className="text-sm font-medium text-white">
                                      Change member type
                                    </p>
                                    <span className="!text-[12px] font-normal text-secondary">
                                      Updates will take effect immediately
                                    </span>
                                  </div>
                                )}
                              </Menu.Item>
                            </div>
                            <hr className="border-[#41485C] my-2" />
                            <div className="px-2">
                              <Menu.Item>
                                {({ active }) => (
                                  <div
                                    className={`${
                                      active ? "bg-[#363D50]" : ""
                                    } cursor-pointer rounded-2xl px-4 py-2 border border-solid border-transparent transition-all duration-150 ease-linear active:!border-[#FFD74A]`}
                                    onClick={() => handleRemoveMember()}
                                  >
                                    <p className="text-sm font-medium text-red-400">
                                      Remove member
                                    </p>
                                    <span className="!text-[12px] font-normal text-secondary">
                                      Employee will no longer have access to
                                      sign in to this service’s dashboard
                                    </span>
                                  </div>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {popup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          id={id}
          userId={userId}
          userType={userType}
        />
      )}
    </div>
  );
};

export default Members;
