import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function EndDate({end_date, setEnd_date, placeholder, error, setError, setToggleEndIcon}) {

  const handleEndDateChange = (date) => {
    setEnd_date(date)
    setToggleEndIcon(false)
    setError((prevError) => ({ ...prevError, end_date: false }));
  }

  const handleFocuse = () => {
    setToggleEndIcon(true)
  }

  return (
    <DatePicker
      selected={end_date}
      onChange={(date) => handleEndDateChange(date)}
      dateFormat="MMM dd yyyy"
      placeholderText={ placeholder ? placeholder : "" }
      className={`focus:outline-none focus:ring-0 focus:shadow-none focus:!border-white ${error?.end_date ? "!border-red-500" : ""}`}      
      popperClassName="date-custome-picker"
      onFocus={handleFocuse}
    />
  )
}

export default EndDate;