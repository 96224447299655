import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { employeeFeedbacksDatalist } from "../../../redux/slices/companyDashboardSlice";
import negativeHoverIcon from "../../../assets/images/nagative_hover.png";
import neutralHoverIcon from "../../../assets/images/neutral_hover.png";
import smileyHoverIcon from "../../../assets/images/smiley_hover.png";
import { getInitials } from "../../../shared/lib/getInitials";
import { getRandomHex } from "../../../shared/lib/getRandomHex";

function FeedbackCard({
  id,
  feedbackId,
  image,
  setId,
  userFeedback,
  userId,
  userName,
  serviceId,
  name,
  setState,
  subName,
  setPopup,
  rating,
  date,
  outlined = false,
  model = false,
  show,
  setShow,
  empid,
  setFeedbackId,
  userid,
  setuserId,
}) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [hexColor, hexWithOpacity] = getRandomHex();
  return (
    <>
      {feedbackId && (
        <div
          onClick={() => {
            if (model) {
              setShow(!show);
              dispatch(employeeFeedbacksDatalist(userid || empid, feedbackId));
              setFeedbackId(feedbackId);
              setuserId(userid);
            } else {
              setId({
                userId: userId,
                serviceId: serviceId,
                rating: rating,
                feedbackId: feedbackId,
              });
              // setPopup(true);
              // setState(13);
            }
          }}
          className={`flex flex-col card-animate gap-[10px] self-start
             ${
               outlined &&
               "border-[1px] hover:border-[#41485C] border-[#596073]"
             }
             bg-[#363D50] rounded-[24px] m-1 cursor-pointer
            `}
        >
          <div className="flex flex-col px-[24px] pt-[24px] pb-[12px] gap-[16px]">
            <div className="flex flex-col gap-[24px]">
              {pathname?.includes("personal_dashboard/teams") ? (
                <div className="flex gap-[16px] items-center">
                  <div className="flex w-[40px] h-[40px] justify-center items-center">
                    <img className="rounded-lg" src={image} alt="service-img" />
                  </div>
                  <div className="flex flex-col ">
                    <span className="text-primary !text-left !font-medium !text-[14px]">
                      {name}
                    </span>
                    <span className="text-secondary !text-left">{subName}</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <span className="text-primary !text-[#FFFFFF] !text-left !font-normal !text-[18px] !font-[20px] !leading-[34px]">
                {userFeedback}
              </span>
              <div className="flex flex-col gap-[12px]">
                <div className="bg-[#596073] h-[1px]" />
                <div className="flex flex-col gap-[10px] md:gap-[0px] md:flex-row md:justify-between md:items-center">
                  <div className="flex gap-[16px] items-center">
                    {pathname?.includes("personal_dashboard/feedbacks") ? (
                      <div className="flex w-[40px] h-[40px] justify-center items-center">
                        <img
                          className="rounded-lg"
                          src={image}
                          alt="service-img"
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: hexWithOpacity,
                          color: hexColor,
                        }}
                        className="w-[40px] h-[40px] rounded-full flex items-center justify-center !text-[14px] !font-semibold"
                      >
                        {getInitials(userName)}
                      </div>
                    )}
                    <div className="flex flex-col ">
                      <span className="text-primary !text-left !font-medium !text-[14px]">
                        {pathname?.includes("personal_dashboard/feedbacks")
                          ? name
                          : userName}
                      </span>
                      <span className="text-secondary !text-left">
                        {subName}
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-end md:justify-start items-center gap-[16px]">
                    <div>
                      <span className="text-[14px] text-[#A5AEBC]">{date?.replace(",",", ")}</span>
                    </div>
                    <div className="flex w-[24px] h-[24px] justify-center items-center">
                      {rating === "negative" && (
                        <img
                          src={negativeHoverIcon}
                          alt="rating-img"
                          className="rating_icon active"
                        />
                      )}

                      {rating === "general" && (
                        <img
                          src={neutralHoverIcon}
                          alt="rating-img"
                          className="rating_icon active"
                        />
                      )}

                      {rating === "positive" && (
                        <img
                          src={smileyHoverIcon}
                          alt="rating-img"
                          className="rating_icon active"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!feedbackId && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          <div className="flex flex-col card-animate gap-[10px] self-start bg-[#363D50] rounded-[24px] m-1 cursor-pointer p-6">
            <Skeleton />
            <Skeleton />
            <Skeleton className="!w-[132px]" />
            <div className="mt-2 flex items-center gap-4">
              <Skeleton className="!w-10 !h-10 !rounded-xl" />
              <Skeleton className="!w-[132px]" />
            </div>
          </div>
        </SkeletonTheme>
      )}
    </>
  );
}

export default FeedbackCard;
