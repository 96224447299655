import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function EndDate({end_date, setEnd_date}) {
  // const [endDate, setEndDate] = useState(new Date());

  const handleEndDateChange = (date) => {
    setEnd_date(date)
  }

  return (
    <DatePicker
      selected={end_date}
      onChange={(date) => handleEndDateChange(date)}
      dateFormat="MMM dd yyyy"
      className="focus:outline-none focus:ring-0 focus:shadow-none focus:!border-white"
      popperClassName="date-custome-picker"
    />
  );
};

export default EndDate;