import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Breadcrumbs from "./breadcrumbs";
import Button from "../../../../../components/Buttons/button";
import { useNavigate, useLocation } from "react-router-dom";

function Header({ path, data }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleServiceName = () => {
    if (data?.service?.id) {
      if (pathname.includes("personal_dashboard")) {
        navigate(
          `/personal_dashboard/team_services/usage_report/${data?.service?.id}`
        );
      } else {
        navigate(
          `/company_dashboard/services/service_report/${data?.service?.id}`
        );
      }
    }
  };

  const handleUserName = () => {
    if (data?.user?.name) {
      if (pathname.includes("personal_dashboard")) {
        navigate(`/personal_dashboard/teams/${data?.user?.id}/employee_report`);
      } else {
        navigate(
          `/company_dashboard/employees/employee_report/${data?.user?.id}`
        );
      }
    }
  };

  return (
    <header className="sticky top-[0px] w-[100%] z-[2] header-gradient flex flex-col gap-[16px]">
      <div className="w-[100%] flex pt-6">
        <Breadcrumbs
          path={path}
          serviceId={
            pathname.includes("employee") ? data?.user?.id : data?.service?.id
          }
        />
      </div>
      <div className="w-[100%] flex flex-col gap-[16px]">
        <div className="flex flex-col md:flex-row md:justify-between pb-[8px] gap-[24px] md:items-center">
          {data?.service?.name && (
            <div className="flex flex-row justify-start gap-[24px] items-center">
              {data?.service?.name !== "Personal Expenses" && (
                <img
                  src={data?.service?.image_url}
                  alt="service"
                  className="w-[72px] h-[72px] card_img rounded-lg"
                />
              )}
              {data?.service?.name === "Personal Expenses" && (
                <div className="w-[72px] h-[72px] flex justify-center items-center rounded-lg bg-[#242B3E]">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.6665 8.00163C4.6665 6.1611 6.15846 4.66699 7.99984 4.66699H23.9998C25.8412 4.66699 27.3332 6.1611 27.3332 8.00163V26.7531C27.3332 27.8154 26.1486 28.4563 25.2602 27.864L23.1079 26.4292C22.8664 26.2681 22.5484 26.2819 22.3217 26.4633L20.5826 27.8546C19.8521 28.4389 18.8142 28.4389 18.0838 27.8546L16.4163 26.5206C16.1728 26.3258 15.8269 26.3258 15.5834 26.5206L13.9159 27.8546C13.1855 28.4389 12.1476 28.4389 11.4171 27.8546L9.67802 26.4633C9.45132 26.2819 9.13332 26.2681 8.89176 26.4292L6.73944 27.864C5.85112 28.4563 4.6665 27.8154 4.6665 26.7531V8.00163ZM7.99984 6.00033C6.89569 6.00033 5.99984 6.89664 5.99984 8.00163V26.7531L8.15216 25.3198C8.87684 24.8366 9.83085 24.878 10.5109 25.4221L12.25 26.8134C12.4935 27.0082 12.8395 27.0082 13.083 26.8134L14.7504 25.4794C15.4809 24.8951 16.5188 24.8951 17.2492 25.4794L18.9167 26.8134C19.1602 27.0082 19.5062 27.0082 19.7496 26.8134L21.4887 25.4221C22.1688 24.878 23.1228 24.8366 23.8475 25.3198L25.9992 26.7542L25.9998 8.00163C25.9998 6.89664 25.104 6.00033 23.9998 6.00033H7.99984ZM9.99984 12.0003C9.99984 11.6321 10.2983 11.3337 10.6665 11.3337H16.6665C17.0347 11.3337 17.3332 11.6321 17.3332 12.0003C17.3332 12.3685 17.0347 12.667 16.6665 12.667H10.6665C10.2983 12.667 9.99984 12.3685 9.99984 12.0003ZM19.9998 12.0003C19.9998 11.6321 20.2983 11.3337 20.6665 11.3337H21.3332C21.7014 11.3337 21.9998 11.6321 21.9998 12.0003C21.9998 12.3685 21.7014 12.667 21.3332 12.667H20.6665C20.2983 12.667 19.9998 12.3685 19.9998 12.0003ZM9.99984 17.3337C9.99984 16.9655 10.2983 16.667 10.6665 16.667H21.3332C21.7014 16.667 21.9998 16.9655 21.9998 17.3337C21.9998 17.7018 21.7014 18.0003 21.3332 18.0003H10.6665C10.2983 18.0003 9.99984 17.7018 9.99984 17.3337Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
              <div className="flex flex-col items-start h-[82px]">
                <span
                  className="text-secondary !text-[14px] !leading-[21px] cursor-pointer"
                  onClick={handleServiceName}
                >
                  {data?.service?.name}
                </span>
                <div className="flex px-[2px] gap-[8px]">
                  <div>
                    <svg
                      width="18"
                      height="22"
                      viewBox="0 0 18 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.3536 17.8536C17.5488 17.6583 17.5488 17.3417 17.3536 17.1464L14.1716 13.9645C13.9763 13.7692 13.6597 13.7692 13.4645 13.9645C13.2692 14.1597 13.2692 14.4763 13.4645 14.6716L16.2929 17.5L13.4645 20.3284C13.2692 20.5237 13.2692 20.8403 13.4645 21.0355C13.6597 21.2308 13.9763 21.2308 14.1716 21.0355L17.3536 17.8536ZM0.5 0V11.5H1.5V0H0.5ZM7 18H17V17H7V18ZM0.5 11.5C0.5 15.0899 3.41015 18 7 18V17C3.96243 17 1.5 14.5376 1.5 11.5H0.5Z"
                        fill="#A5AEBC"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <span
                      className="text-primary !text-[24px] !leading-[36px] cursor-pointer"
                      onClick={handleUserName}
                    >
                      {data?.user?.name}
                    </span>
                    <span className="text-secondary !text-[14px] !leading-[21px]">
                      {data?.service?.category_name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!data?.service?.name && (
            <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
              <Skeleton className="!w-[150px] !h-[90px]" />
            </SkeletonTheme>
          )}
        </div>
        <div className="bg-[#41485C] w-[100%] h-[2px]" />
      </div>
    </header>
  );
}

export default Header;
