import React, { useEffect } from "react";
import RadioButton from "../../../../RadioButton/RadioButton";
import Select from "../../../../SearchSelect/select";
import TextField from "../../../../TextField/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPersonalDashboard,
  updateSubmissionData,
} from "../../../../../redux/slices/personalDashboardSlice";

function Third() {
  const dispatch = useDispatch();
  const { updateSubmissionsData, allServicesData } = useSelector(
    selectPersonalDashboard
  );

  const handleInput = (field, value) => {
    dispatch(updateSubmissionData({ field, value }));
  };

  useEffect(() => {
    if (updateSubmissionsData?.suggesting_reason_type !== "good_replacement") {
      handleInput("service_to_replace_id", null);
    }
  }, [updateSubmissionsData?.suggesting_reason_type]);

  return (
    <div className="flex flex-col md:w-[536px] md:h-[404px] pr-[16px] pb-[16px] gap-[24px]">
      <div className="flex flex-start text-left">
        <span className="text-primary !text-[18px] !leading-[36px]">
          Why are you suggesting this service?
        </span>
      </div>
      <div className="">
        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={
              updateSubmissionsData?.suggesting_reason_type === "not_provided"
            }
            onChange={() =>
              handleInput("suggesting_reason_type", "not_provided")
            }
          />
          <div className="text-left w-full">
            <span
              className="text-secondary !text-[18px] cursor-pointer w-full block"
              onClick={() =>
                handleInput("suggesting_reason_type", "not_provided")
              }
            >
              The company doesn’t provide a service like it.
            </span>
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={
              updateSubmissionsData?.suggesting_reason_type ===
              "good_replacement"
            }
            onChange={() =>
              handleInput("suggesting_reason_type", "good_replacement")
            }
          />
          <div className="flex flex-col text-left w-full">
            <span
              className="text-secondary text-left !text-[18px] mb-[4px] cursor-pointer w-full block"
              onClick={() =>
                handleInput("suggesting_reason_type", "good_replacement")
              }
            >
              It would be a good replacement for another service the company
              provides
            </span>
            {updateSubmissionsData?.suggesting_reason_type ===
              "good_replacement" && (
              <Select
                services={allServicesData}
                onChange={(e) =>
                  handleInput("suggesting_reason_type", e.target.value)
                }
                suggestingReasonType={
                  updateSubmissionsData.suggesting_reason_type
                }
              />
            )}
          </div>
        </div>
        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={updateSubmissionsData?.suggesting_reason_type === "other"}
            onChange={() => handleInput("suggesting_reason_type", "other")}
          />
          <div className="flex flex-col w-full text-left">
            <span
              className="text-secondary text-left !text-[18px] mb-[4px] cursor-pointer w-full block"
              onClick={() => handleInput("suggesting_reason_type", "other")}
            >
              Other
            </span>
            {updateSubmissionsData?.suggesting_reason_type === "other" && (
              <TextField
                placeholder={"Please explain"}
                value={updateSubmissionsData?.suggesting_reason_detail}
                onChange={(e) =>
                  handleInput("suggesting_reason_detail", e.target.value)
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Third;
