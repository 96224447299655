import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormattedAmount } from "../../../hooks/useFormattedAmount";
import { getColorHex } from "../../../shared/lib/getColorHex";
import { getInitials } from "../../../shared/lib/getInitials";
import Button from "../../Buttons/button";
function ReceiptCell({ data, viewDetail, veiwReceipt, setReceiptData }) {
  const dropdownRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const receiptData = data?.receipt;

  const dashboard = location.pathname.includes("personal_dashboard")
    ? "personal"
    : "company";
  const [dropdown, setDropdown] = useState(false);
  const handleDropdown = () => {
    setDropdown(!dropdown);
  };
  const handleDetail = () => {
    viewDetail(data?.id, data?.employee?.id);
    setDropdown(false);
  };
  const openReceipt = () => {
    veiwReceipt();
    setDropdown(false);
    setReceiptData(receiptData);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };
  useEffect(() => {
    var modal = document.querySelectorAll('[id^="headlessui-dialog-:"]');
    modal[0].addEventListener("click", handleClickOutside);
    return () => {
      modal[0].removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="relative flex items-center justify-end" ref={dropdownRef}>
      <Button
        onClick={handleDropdown}
        outlined={true}
        hoverColor={true}
        secondary={true}
        secondaryHoverOutlined={true}
        className={"!border-transparent"}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="19"
            r="2"
            transform="rotate(180 12 19)"
            fill="white"
          />
          <circle
            cx="12"
            cy="12"
            r="2"
            transform="rotate(180 12 12)"
            fill="white"
          />
          <circle
            cx="12"
            cy="5"
            r="2"
            transform="rotate(180 12 5)"
            fill="white"
          />
        </svg>
      </Button>
      {dropdown && (
        <div className="absolute top-[54px] right-[-16px] z-20 bg-[#293043] w-[300px] py-2 rounded-3xl">
          <div className="px-2">
            <button
              onClick={handleDetail}
              className="w-full text-left px-4 py-2 rounded-2xl transition-all duration-150 ease-linear hover:bg-[#363D50] border border-solid border-transparent active:border-[#FFD74A] text-[14px] font-medium text-white"
            >
              View expense details
            </button>
          </div>
          <div className="w-full my-2 h-[1px] bg-[#434A5D]" />
          <div className="px-2">
            <button
              onClick={openReceipt}
              className="w-full text-left px-4 py-2 rounded-2xl transition-all duration-150 ease-linear hover:bg-[#363D50] border border-solid border-transparent active:border-[#FFD74A] text-[14px] font-medium text-white"
            >
              View expense receipt
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function UserExpensesTableBody({
  tableData,
  setState,
  setPopup,
  setReceiptData,
  show,
  setShow,
  setId,
}) {
  const formatFunction = useFormattedAmount();
  const handleViewDetail = (id, userId) => {
    setShow(!show);
    // setId({ id: id, user_id: userId });
    setId(id);
  };
  const handleReceipt = (data) => {
    if (data?.receipt) {
      setPopup(true);
      setState(57);
    }
  };

  const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "receipt.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };

  return (
    <tbody>
      {tableData &&
        tableData.map((row, index) => {
          const [hexColor, hexWithOpacity] = getColorHex(row.id);
          return (
            <tr
              key={index}
              className="cursor-pointer rounded-[24px]"
              onClick={() => {
                setShow(!show);
                // setId({ id: row?.id, user_id: row?.userId });
                setId(row.id);
              }}
            >
              {Object.keys(row)
                .filter((item) => item !== "id")
                .map((item, index2) => (
                  <td
                    key={index2}
                    className={cn(
                      "text-secondary !text-left !font-medium !text-[#FFFFFF] px-[16px] py-[20px]"
                    )}
                  >
                    {(item === "expense" ||
                      item === "category" ||
                      item === "transaction_date") &&
                      row[item]}
                    {item === "cost" && formatFunction(row[item])}
                    {item === "reason" && (
                      <div className="group relative">
                        <p className="max-w-[200px] truncate">{row[item]}</p>
                        <div class="absolute top-[30px] flex flex-col items-center hidden mb-5 group-hover:flex">
                          <div class="w-3 h-3 -mb-2 rotate-45 bg-[#242B3E]"></div>
                          <span class="relative rounded-md z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-[#242B3E] shadow-lg">
                            {row[item]}
                          </span>
                        </div>
                      </div>
                    )}
                    {item === "receipt" &&
                      (row[item] ? (
                        <div
                          onClick={(event) => event.stopPropagation()}
                          className="relative group"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M3.5 6.00098C3.5 4.62058 4.61897 3.5 6 3.5H18C19.381 3.5 20.5 4.62058 20.5 6.00098V20.0646C20.5 20.8613 19.6115 21.3419 18.9453 20.8978L17.3311 19.8216C17.1499 19.7009 16.9114 19.7112 16.7414 19.8472L15.437 20.8907C14.8892 21.3289 14.1108 21.3289 13.563 20.8907L12.3123 19.8902C12.1297 19.7441 11.8703 19.7441 11.6877 19.8902L10.437 20.8907C9.88921 21.3289 9.11079 21.3289 8.56296 20.8907L7.25864 19.8472C7.08861 19.7112 6.85011 19.7009 6.66894 19.8216L5.0547 20.8978C4.38846 21.3419 3.5 20.8613 3.5 20.0646V6.00098ZM6 4.5C5.17189 4.5 4.5 5.17223 4.5 6.00098V20.0646L6.11424 18.9896C6.65775 18.6272 7.37326 18.6583 7.88333 19.0664L9.18765 20.1098C9.37026 20.2559 9.62974 20.2559 9.81235 20.1098L11.063 19.1093C11.6108 18.6711 12.3892 18.6711 12.937 19.1093L14.1877 20.1098C14.3703 20.2559 14.6297 20.2559 14.8123 20.1098L16.1167 19.0664C16.6267 18.6583 17.3423 18.6272 17.8858 18.9896L19.4995 20.0654L19.5 6.00098C19.5 5.17223 18.8281 4.5 18 4.5H6ZM7.5 9C7.5 8.72386 7.72386 8.5 8 8.5H12.5C12.7761 8.5 13 8.72386 13 9C13 9.27614 12.7761 9.5 12.5 9.5H8C7.72386 9.5 7.5 9.27614 7.5 9ZM15 9C15 8.72386 15.2239 8.5 15.5 8.5H16C16.2761 8.5 16.5 8.72386 16.5 9C16.5 9.27614 16.2761 9.5 16 9.5H15.5C15.2239 9.5 15 9.27614 15 9ZM7.5 13C7.5 12.7239 7.72386 12.5 8 12.5H16C16.2761 12.5 16.5 12.7239 16.5 13C16.5 13.2761 16.2761 13.5 16 13.5H8C7.72386 13.5 7.5 13.2761 7.5 13Z"
                              fill="#FFD74A"
                            />
                          </svg>
                          <div className="absolute top-6 right-[80px] z-20 hidden group-hover:block w-[257px] bg-[#293043] rounded-2xl p-2">
                            <div className="flex items-center justify-between">
                              <p className="text-secondary !text-xs">
                                File name
                              </p>
                              <div className="flex items-center gap-3">
                                <button
                                  onClick={() => handleDownload(row["receipt"])}
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.417 3.4196C10.417 3.18948 10.2304 3.00293 10.0003 3.00293C9.77021 3.00293 9.58366 3.18948 9.58366 3.4196L9.58366 11.5803L6.96162 8.9583C6.7989 8.79558 6.53508 8.79558 6.37236 8.9583C6.20965 9.12102 6.20965 9.38484 6.37236 9.54756L9.41107 12.5863C9.73651 12.9117 10.2641 12.9117 10.5896 12.5863L13.6283 9.54756C13.791 9.38484 13.791 9.12102 13.6283 8.9583C13.4656 8.79558 13.2017 8.79558 13.039 8.9583L10.417 11.5803V3.4196Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M2.91699 14.9137C2.91699 16.0643 3.84973 16.9971 5.00033 16.9971H15.0003C16.1509 16.9971 17.0837 16.0643 17.0837 14.9137V12.4137C17.0837 12.1836 16.8971 11.9971 16.667 11.9971C16.4369 11.9971 16.2503 12.1836 16.2503 12.4137V14.9137C16.2503 15.6041 15.6907 16.1637 15.0003 16.1637H5.00033C4.30997 16.1637 3.75033 15.6041 3.75033 14.9137L3.75033 12.4137C3.75033 12.1836 3.56378 11.9971 3.33366 11.9971C3.10354 11.9971 2.91699 12.1836 2.91699 12.4137V14.9137Z"
                                      fill="white"
                                    />
                                  </svg>
                                </button>
                                <button
                                  onClick={() => {
                                    setPopup(true);
                                    setState(57);
                                    setReceiptData(row["receipt"]);
                                  }}
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.6663 4.16634C16.6663 3.94533 16.5785 3.73337 16.4223 3.57709C16.266 3.42081 16.054 3.33301 15.833 3.33301H11.6663C11.4453 3.33301 11.2334 3.42081 11.0771 3.57709C10.9208 3.73337 10.833 3.94533 10.833 4.16634C10.833 4.38735 10.9208 4.59932 11.0771 4.7556C11.2334 4.91188 11.4453 4.99967 11.6663 4.99967H13.808L11.0747 7.74134C10.9966 7.81881 10.9346 7.91098 10.8923 8.01253C10.85 8.11408 10.8282 8.223 10.8282 8.33301C10.8282 8.44302 10.85 8.55194 10.8923 8.65349C10.9346 8.75504 10.9966 8.84721 11.0747 8.92467C11.1521 9.00278 11.2443 9.06478 11.3459 9.10708C11.4474 9.14939 11.5563 9.17117 11.6663 9.17117C11.7764 9.17117 11.8853 9.14939 11.9868 9.10708C12.0884 9.06478 12.1805 9.00278 12.258 8.92467L14.9997 6.18301V8.33301C14.9997 8.55402 15.0875 8.76598 15.2438 8.92226C15.4 9.07854 15.612 9.16634 15.833 9.16634C16.054 9.16634 16.266 9.07854 16.4223 8.92226C16.5785 8.76598 16.6663 8.55402 16.6663 8.33301V4.16634ZM8.92467 11.0747C8.84721 10.9966 8.75504 10.9346 8.65349 10.8923C8.55194 10.85 8.44302 10.8282 8.33301 10.8282C8.223 10.8282 8.11408 10.85 8.01253 10.8923C7.91098 10.9346 7.81881 10.9966 7.74134 11.0747L4.99967 13.808V11.6663C4.99967 11.4453 4.91188 11.2334 4.7556 11.0771C4.59932 10.9208 4.38735 10.833 4.16634 10.833C3.94533 10.833 3.73337 10.9208 3.57709 11.0771C3.42081 11.2334 3.33301 11.4453 3.33301 11.6663V15.833C3.33301 16.054 3.42081 16.266 3.57709 16.4223C3.73337 16.5785 3.94533 16.6663 4.16634 16.6663H8.33301C8.55402 16.6663 8.76598 16.5785 8.92226 16.4223C9.07854 16.266 9.16634 16.054 9.16634 15.833C9.16634 15.612 9.07854 15.4 8.92226 15.2438C8.76598 15.0875 8.55402 14.9997 8.33301 14.9997H6.18301L8.92467 12.258C9.00278 12.1805 9.06478 12.0884 9.10708 11.9868C9.14939 11.8853 9.17117 11.7764 9.17117 11.6663C9.17117 11.5563 9.14939 11.4474 9.10708 11.3459C9.06478 11.2443 9.00278 11.1521 8.92467 11.0747Z"
                                      fill="white"
                                      stroke="#293043"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <div className="mt-2.5 max-h-[321px]">
                              <img
                                className="w-full h-full rounded-xl"
                                alt="receipt"
                                src={row["receipt"]}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        "_"
                      ))}
                  </td>
                ))}
              <td
                onClick={(event) => event.stopPropagation()}
                className="text-secondary px-[16px] py-[20px] text-right"
              >
                <ReceiptCell
                  data={row}
                  viewDetail={handleViewDetail}
                  veiwReceipt={() => {
                    handleReceipt(row);
                  }}
                  setReceiptData={setReceiptData}
                />
              </td>
            </tr>
          );
        })}
      {!tableData && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          {[1, 2, 3, 4, 5].map((el, index) => {
            return (
              <tr key={index}>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
              </tr>
            );
          })}
        </SkeletonTheme>
      )}
    </tbody>
  );
}

export default UserExpensesTableBody;
