import React from "react";
import cn from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useFormattedAmount } from "../../../hooks/useFormattedAmount";

function PrintingUsageTableBody({ tableData, setState, setPopup, setDataPlanId }) {
  const formatFunction = useFormattedAmount();

  return (
    <tbody>
      {tableData &&
        tableData.map((row, index) => (
          <tr key={index} className="rounded-[24px]">
            {Object.keys(row)
              .filter((item) => item !== "id")
              .map((item, index2) => (
                <td
                  key={index2}
                  className={cn(
                    "text-secondary !font-medium !text-[#FFFFFF] px-[16px] py-[20px] !leading-5",
                    {
                      "!px-[10px] w-[15%]": item === "license_type",
                      "min-w-[155px]": item === "bw_pages_count" || item === "color_pages_count" || item === "license_fee",
                      "min-w-[255px]": item === "contractual_cost",
                    }
                  )}
                >
                  {(item === "license_type" ||
                    item === "bw_pages_count" ||
                    item === "color_pages_count" ) &&
                     (row[item])
                  }
                  {item === "license_fee" && `${formatFunction(row[item])}` }
                  {item === "contractual_cost" &&
                    `${formatFunction(row[item])}/${formatFunction(
                      row["actual_cost"]
                    )}`
                  }
                </td>
              ))}
          </tr>
        ))}
      {!tableData && (
        <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
          {[1, 2, 3, 4, 5].map((el, index) => {
            return (
              <tr key={index}>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
                <td className="px-3 py-4">
                  <Skeleton />
                </td>
              </tr>
            );
          })}
        </SkeletonTheme>
      )}
    </tbody>
  );
}

export default PrintingUsageTableBody;
