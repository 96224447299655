import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import fileUpload from "./../../assets/images/ic-upload.png";

const fileTypes = ["XLSX", "PDF"];

function DragDrop({ setFile }) {
  const [newFile, setNewFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
    setNewFile(file);
  };
  return (
    <div className="customDragDrop">
      <div
        className={`DragDropContainer ${
          newFile ? "!border-[#596073] !border-solid" : ""
        }`}
      >
        {!newFile && (
          <img src={fileUpload} alt="icon" className="DragDropImg" />
        )}
        {newFile && (
          <div className="w-10 h-10 flex items-center justify-center rounded-lg bg-[#6BE26114]">
            <svg
              width="26"
              height="17"
              viewBox="0 0 26 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.0893 0.910582C25.4147 1.23602 25.4147 1.76366 25.0893 2.08909L11.613 15.5654C9.9858 17.1926 7.34762 17.1926 5.72043 15.5654L0.577452 10.4224C0.252015 10.097 0.252015 9.56935 0.577452 9.24391C0.902889 8.91848 1.43053 8.91848 1.75596 9.24391L6.89894 14.3869C7.87525 15.3632 9.45816 15.3632 10.4345 14.3869L23.9108 0.910582C24.2362 0.585145 24.7639 0.585145 25.0893 0.910582Z"
                fill="#6DEE6D"
              />
            </svg>
          </div>
        )}
        {!newFile && (
          <div className="DragDropHeading">Drag and drop Excel file</div>
        )}
        {newFile && (
          <div className="DragDropHeading">File successfully loaded!</div>
        )}
        {!newFile && (
          <div className="fileText">
            <span>or</span>
            <span className="selectText">Select a file</span>
          </div>
        )}
        {newFile && (
          <div className="fileText">
            <span className="selectText">Upload new file</span>
          </div>
        )}
      </div>
      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
    </div>
  );
}

export default DragDrop;
