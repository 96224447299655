import React from 'react'
import ServiceSetup from './tabs/service_setup';
import ServiceInfo from './tabs/service_info';
import ServiceOwner from './tabs/service_owner';
import UserUsegesData from './tabs/user_useges_data';
import ContractData from './tabs/contract_data';
import ProcurementPOC from './tabs/procurement';

function Tabs({
    id,
    Tab,
    setTab,
    formValid,
    formValidName,
    // formValidEmail,
    owner_name,
    setOwner_name,
    service_name,
    setService_name,
    category_name,
    setCategory_name,
    file,
    setFile,
    selectedDate,
    setSelectedDate,
    poc_name,
    setPoc_name,
    poc_email,
    setPoc_email,
    poc_phone,
    setPoc_phone,
    start_date,
    setStart_date,
    end_date,
    setEnd_date,
    contract_file,
    setContract_file
  }) {

  return (
    <div>
      {Tab === 1 && <ServiceSetup formValid={formValid} setTab={setTab} tab={Tab} id={id} owner_name={owner_name} service_name={service_name} category_name={category_name} poc_name={poc_name} poc_email={poc_email} poc_phone={poc_phone} contract_file={contract_file} file={file} />}
      {Tab === 2 && <ServiceInfo  formValid={formValid} setTab={setTab} id={id} service_name={service_name} setService_name={setService_name} category_name={category_name} setCategory_name={setCategory_name} />}
      {Tab === 3 && <ServiceOwner formValid={formValid} setTab={setTab} owner_name={owner_name} setOwner_name={setOwner_name} />}
      {Tab === 4 && <ContractData formValid={formValid} setTab={setTab} id={id} start_date={start_date} setStart_date={setStart_date} end_date={end_date} setEnd_date={setEnd_date} contract_file={contract_file} setContract_file ={setContract_file}/>}
      {Tab === 5 && <UserUsegesData formValid={formValid} setTab={setTab} id={id} file={file} setFile={setFile} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>}
      {Tab === 6 && <ProcurementPOC formValidName={formValidName}  setTab={setTab} id={id} poc_name={poc_name} setPoc_name={setPoc_name} poc_email={poc_email} setPoc_email={setPoc_email} poc_phone={poc_phone} setPoc_phone={setPoc_phone} />}
    </div>
  )
}

export default Tabs