import React from 'react'
import cn from 'classnames'
function TextField({ children, className, value, placeholder, onChange, onKeyDown, onFocus, onBlur }) {
  return (
    <input
      value={value}
      type="text"
      onChange={onChange}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      className={
        cn('bg-[#363D50] placeholder:text-[#A5AEBC] focus:border-white !text-white focus:text-white border border-[#596073] rounded-[12px] focus:ring-[#596073] focus:border-[#596073] block w-full px-[16px] py-[12px] custom_text', className)
      }
    />
  )
}

export default TextField;
