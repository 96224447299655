import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function StartDate({start_date, setStart_date}) {

  const handleStartDateChange = (date) => {
    setStart_date(date)
  }

  return (
    <DatePicker
      selected={start_date}
      onChange={(date) => handleStartDateChange(date)}
      dateFormat="MMM dd yyyy"
      className="focus:outline-none focus:ring-0 focus:shadow-none focus:!border-white"
      popperClassName="date-custome-picker"
    />
  );
};

export default StartDate;