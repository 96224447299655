import React from "react";
import cn from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Button from "../../../../../components/Buttons/button";
import {
  getUsageServiceData,
  setEmptyUsageCompanyReportData,
} from "../../../../../redux/slices/companyDashboardSlice";
import { useFormattedAmount } from "../../../../../hooks/useFormattedAmount";
import { getInitials } from "../../../../../shared/lib/getInitials";
import { getColorHex } from "../../../../../shared/lib/getColorHex";


function UserCostCards({
  setPopup,
  setState,
  data,
  id,
  serviceId,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const formatFunction = useFormattedAmount();
  const goToEmployeeReport = async (id, serviceid) => {
    dispatch(setEmptyUsageCompanyReportData());
    if (pathname?.includes("home")) {
      navigate(
        `/company_dashboard/home/service/usage_report/${serviceid}?dataId=${id}`,
        {
          state: { id: id, serviceid: serviceid, page: "company_dashboard" },
          replace: false,
        }
      );
    } else {
      navigate(
        `/company_dashboard/services/usage_report/${serviceid}?dataId=${id}`,
        {
          state: { id: id, serviceid: serviceid, page: "company_dashboard" },
          replace: false,
        }
      );
    }
  };
  return (
    <div className={`grid grid-cols-1 gap-[32px] justify-center mb-8`}>
      <div className="flex flex-col bg-[#363D50] pb-[16px] rounded-[32px] w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.1667 3.33333C14.0621 3.33333 13.1667 4.22876 13.1667 5.33333V5.33432C13.1672 6.43844 14.0624 7.33333 15.1667 7.33333H17.8333C18.9379 7.33333 19.8333 6.4379 19.8333 5.33333C19.8333 4.22876 18.9379 3.33333 17.8333 3.33333H15.1667ZM15.1667 2C13.554 2 12.2089 3.14517 11.9 4.66667H9.83333C8.94928 4.66667 8.10143 5.01786 7.47631 5.64298C6.85119 6.2681 6.5 7.11594 6.5 8V24C6.5 24.8841 6.85119 25.7319 7.47631 26.357C8.10143 26.9821 8.94928 27.3333 9.83333 27.3333H23.1667C24.0507 27.3333 24.8986 26.9821 25.5237 26.357C26.1488 25.7319 26.5 24.8841 26.5 24V8C26.5 7.11594 26.1488 6.2681 25.5237 5.64298C24.8986 5.01786 24.0507 4.66667 23.1667 4.66667H21.1C20.7911 3.14517 19.446 2 17.8333 2H15.1667ZM9.83333 6H11.9C12.2089 7.52149 13.554 8.66667 15.1667 8.66667H17.8333C19.446 8.66667 20.7911 7.52149 21.1 6H23.1667C23.6971 6 24.2058 6.21071 24.5809 6.58579C24.956 6.96086 25.1667 7.46957 25.1667 8V24C25.1667 24.5304 24.956 25.0391 24.5809 25.4142C24.2058 25.7893 23.6971 26 23.1667 26H9.83333C9.3029 26 8.79419 25.7893 8.41912 25.4142C8.04405 25.0391 7.83333 24.5304 7.83333 24V8C7.83333 7.46957 8.04405 6.96086 8.41912 6.58579C8.79419 6.21071 9.3029 6 9.83333 6ZM13.5 14.3333C13.5 12.6765 14.8431 11.3333 16.5 11.3333C18.1568 11.3333 19.5 12.6765 19.5 14.3333C19.5 15.9902 18.1568 17.3333 16.5 17.3333C14.8431 17.3333 13.5 15.9902 13.5 14.3333ZM16.5 12.6667C15.5795 12.6667 14.8333 13.4129 14.8333 14.3333C14.8333 15.2538 15.5795 16 16.5 16C17.4204 16 18.1666 15.2538 18.1666 14.3333C18.1666 13.4129 17.4204 12.6667 16.5 12.6667ZM16.5 20.6667C14.7337 20.6667 13.2044 21.6127 12.412 22.9977C12.2292 23.3173 11.8219 23.4282 11.5023 23.2453C11.1827 23.0625 11.0718 22.6552 11.2547 22.3356C12.2819 20.5401 14.2502 19.3333 16.5 19.3333C18.7498 19.3333 20.7181 20.5401 21.7453 22.3356C21.9282 22.6552 21.8173 23.0625 21.4977 23.2453C21.1781 23.4282 20.7708 23.3173 20.588 22.9977C19.7956 21.6127 18.2663 20.6667 16.5 20.6667Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Highest cost users
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Users with a high price tag
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-[16px]">
          <div className="flex flex-col max-w-[100%] h-[360px] px-[3px] py-[3px] overflow-y-scroll scrollbar-hide">
            {data?.highest_cost_users?.map((employee, index) => {
              const [hexColor, hexWithOpacity] = getColorHex(employee.id);
              return (
                <div
                  key={index}
                  className="flex justify-between items-center card-animate cursor-pointer py-[25px] px-[5px] border-t-[1px] border-[#41485C] hover:rounded-[8px]"
                  onClick={() => {
                    goToEmployeeReport(employee.id, serviceId);
                  }}
                >
                  <div className="flex gap-[16px] items-center">
                    <div
                      style={{
                        backgroundColor: hexWithOpacity,
                        color: hexColor,
                      }}
                      className="w-[40px] h-[40px] rounded-full flex items-center justify-center font-semibold text-[14px]"
                    >
                      {getInitials(employee.name)}
                    </div>
                    <div className="flex flex-col">
                      <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-left">
                        {employee.name}
                      </span>
                      <span className="text-secondary !leading-[20px] !text-left">
                        {employee.distinction}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-right">
                      {formatFunction(employee.cost_per_month)}
                    </span>
                    <span className="text-secondary !leading-[20px] !text-right">
                      Cost per month
                    </span>
                  </div>
                </div>
              );
            })}
            {!data?.highest_cost_users && (
              <SkeletonTheme baseColor="#3B4255" highlightColor="#0000001a">
                <Skeleton count={3} />
              </SkeletonTheme>
            )}
          </div>
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Button
            className={cn({
              invisible: !data?.highest_cost_users?.length,
            })}
            onClick={() => {
              setPopup(true);
              setState(19);
              dispatch(getUsageServiceData(id));
            }}
            secondary
            hoverColor
          >
            View all
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UserCostCards;
