import React, { useEffect, useState, useRef } from "react";
import RadioButton from "../../../../RadioButton/RadioButton";
import TextField from "../../../../TextField/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPersonalDashboard,
  updateSubmissionData,
} from "../../../../../redux/slices/personalDashboardSlice";
import {
  getInitializeServicesData,
  selectCompanyDashboard,
} from "../../../../../redux/slices/companyDashboardSlice";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

function Second() {
  const dispatch = useDispatch();
  const { updateSubmissionsData } = useSelector(selectPersonalDashboard);
  const State = useSelector(selectCompanyDashboard);
  const { CategoriesAndUsersData } = State;
  const companyUsers = CategoriesAndUsersData.company_users;
  const [searchResults, setSearchResults] = useState([]);
  const [selected, setSelected] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1);
  const ulItemRefs = useRef([]);
  const servicesUlItemRefs = useRef([]);

  const handleUserInput = (field, value) => {
    if (value.trim() === "") {
      setSearchText("");
      setSearchResults([]);
    } else {
      setSearchText(value);

      const filteredUsers = companyUsers.filter((user) => {
        const searchTerm = value.toLowerCase();
        return (
          user.name.toLowerCase().includes(searchTerm) ||
          user.email.toLowerCase().includes(searchTerm) ||
          user.id.toString().includes(searchTerm)
        );
      });
      setSearchResults(filteredUsers);
    }
    // dispatch(updateSubmissionData({ field, value }));
  };

  const handleInput = (field, value) => {
    dispatch(updateSubmissionData({ field, value }));
  };

  const setButtonRef = (element, index) => {
    ulItemRefs.current = ulItemRefs.current.filter(Boolean);
    ulItemRefs.current[index] = element;
  };

  const handleResultClick = (result) => {
    handleUserInput("expensed_by_name", result.name);
    dispatch(
      updateSubmissionData({ field: "expensed_by_name", value: result.name })
    );
    setSearchResults([]);
    setSelected(result);
  };

  const handleKeyDown = (e, result) => {
    if (!searchResults.length || !ulItemRefs.current.length) {
      return;
    }

    if (e.key === "ArrowDown") {
      const nextIndex = (activeIndex + 1) % ulItemRefs.current.length;
      setActiveIndex(nextIndex);
      ulItemRefs.current[nextIndex]?.focus();
      return;
    }

    if (e.key === "ArrowUp") {
      const prevIndex =
        (activeIndex - 1 + ulItemRefs.current.length) %
        ulItemRefs.current.length;
      setActiveIndex(prevIndex);
      ulItemRefs.current[prevIndex]?.focus();
      return;
    }

    if (e.key === "Enter" && result?.name) {
      handleResultClick(result);
    }
  };

  useEffect(() => {
    dispatch(getInitializeServicesData());
  }, []);

  return (
    <div className="flex flex-col md:w-[536px] md:h-[404px] pr-[16px] pb-[16px] gap-[24px]">
      <div className="flex flex-start">
        <span className="text-primary text-left !text-[18px] !leading-[36px]">
          Is this service currently expensed for work?
        </span>
      </div>
      <div className="">
        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={updateSubmissionsData?.expense_type === "expensed_by_me"}
            onChange={() => handleInput("expense_type", "expensed_by_me")}
          />
          <div className="text-left w-full">
            <span
              className="text-secondary !text-[18px] cursor-pointer w-full block"
              onClick={() => handleInput("expense_type", "expensed_by_me")}
            >
              Yes - this service is expensed by me.
            </span>
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={
              updateSubmissionsData?.expense_type === "expensed_by_other"
            }
            onChange={() => handleInput("expense_type", "expensed_by_other")}
          />
          <div className="flex flex-col text-left w-full">
            <span
              className="text-secondary !text-[18px] mb-[4px] cursor-pointer w-full block"
              onClick={() => handleInput("expense_type", "expensed_by_other")}
            >
              Yes - this service is expensed by someone else.
            </span>
            {updateSubmissionsData?.expense_type === "expensed_by_other" && (
              <TextField
                placeholder={"Enter person, group, or team name"}
                value={searchText}
                onChange={(e) =>
                  handleUserInput("expensed_by_name", e.target.value)
                }
              />
            )}
            {searchResults.length > 0 && (
              <div className="relative">
                <ul
                  tabIndex={0}
                  className="z-10 absolute mt-1 max-h-40 scrollbar-hide w-full overflow-auto bg-[#293043] p-2 rounded-[16px]"
                >
                  {searchResults.map((result, i) => (
                    <li
                      tabIndex={i}
                      ref={(el) => setButtonRef(el, i)}
                      key={result.id}
                      onKeyDown={(e) => handleKeyDown(e, result)}
                      onClick={() => handleResultClick(result)}
                      className={`relative cursor-default py-2 pl-10 pr-4 text-left transition-colors duration-300 hover:bg-[#363D50] rounded-[12px] border border-solid border-transparent active:border-[#FFD74A] mb-2 last:mb-0 ${
                        selected === result
                          ? "bg-[#363D50] !border-[#FFD74A]"
                          : ""
                      }`}
                    >
                      <div className="flex flex-col">
                        <span className="text-primary">{result.name}</span>
                        <span className="text-secondary">
                          {result.distinction}
                        </span>
                      </div>

                      {selected === result && (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={updateSubmissionsData?.expense_type === "not_expensed"}
            onChange={() => handleInput("expense_type", "not_expensed")}
          />
          <div className="text-left w-full">
            <span
              className="text-secondary !text-[18px] cursor-pointer w-full block"
              onClick={() => handleInput("expense_type", "not_expensed")}
            >
              No - This service isn’t expensed
            </span>
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={updateSubmissionsData?.expense_type === "no_idea"}
            onChange={() => handleInput("expense_type", "no_idea")}
          />
          <div className="text-left w-full">
            <span
              className="text-secondary !text-[18px] cursor-pointer w-full block"
              onClick={() => handleInput("expense_type", "no_idea")}
            >
              I don’t know
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Second;
