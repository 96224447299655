import React from "react";

function Button({
  children,
  onClick,
  fill,
  outlined,
  secondary,
  hoverOutlined,
  secondaryHoverOutlined,
  hoverColor,
  primaryHover,
  secondaryHover,
  className,
  position,
  underline,
  onMouseEnter,
  onMouseLeave,
  maxWidth,
  disabled
}) {
  return (
    <button
      className={`
        ${fill &&
        "bg-[#FFD74A] hover:bg-[#FFE766] focus:bg-[#EAC400] !text-[#242B3E] pt-[12px] pl-[16px] pb-[12px] pr-[16px]"
        }
        ${outlined &&
        "!border-[#596073] hover:!border-[#A5AEBC] focus:!border-[#EAC400] hover:color-[#FFE766] focus:color-[#EAC400] pt-[12px] pl-[20px] pb-[12px] pr-[20px]"
        }
        ${secondary && "!text-[#FFD74A]"}
        ${hoverOutlined &&
        "hover:scale-x-5 hover:scale-y-5 hover:!border-[#939DAD] pt-[12px] pl-[20px] pb-[12px] pr-[20px]"
        }
        ${secondaryHoverOutlined &&
        "active:!border-[#FFD74A] active:scale-[0.9] hover:!border-[#596073] py-[12px] px-[15px] transition duration-500"
        }
        ${primaryHover && "hover:!text-white"}
        ${secondaryHover && "hover:!text-[#FFD74A]"}
        ${hoverColor && "hover:brightness-125"}
        ${underline && "underline"}
        ${position === 'center' && "justify-center items-center"}
        ${position === 'left' && "justify-left items-left"}
        ${position === 'right' && "justify-right items-right"}
        ${maxWidth && "max-xs:min-w-[150px] sm:min-w-[150px]"}
        ${className}
        w-[fit-content] group transform border-[1px]  border-[rgba(255,255,255,0)] h-[fit-content] rounded-[16px] !text-[14px] text-primary flex gap-[8px]
        `}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
