import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  logInRequest,
  selectUser,
  setLoading,
} from "../../redux/slices/userSlice";
import { toast } from "react-toastify";
import SuccessIcon from "../../assets/icons/success-check.svg";
import LogoImage from "../../assets/images/logo_new.svg";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [inValidAuth, setInvalidAuth] = useState(false);
  const passwordField = useRef(null);
  const location = useLocation();

  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setInvalidAuth(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setInvalidAuth(false);
  };

  const handleShowPassword = () => {
    if (showPassword) {
      if (passwordField.current && passwordField.current.type) {
        passwordField.current.type = "password";
      }
      setShowPassword(false);
    } else {
      if (passwordField.current && passwordField.current.type) {
        passwordField.current.type = "text";
      }
      setShowPassword(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (email === "") {
      setErrorMessage("* Email is required");
    } else if (password === "") {
      setErrorMessage("* Password is required");
    } else {
      // Perform login logic here
      setErrorMessage("");
      dispatch(setLoading(true));
      localStorage.setItem("logInCalled", "true");
      dispatch(
        logInRequest({
          email: email,
          password: password,
        })
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("logInCalled") === "true" && !user.isLoading) {
      localStorage.removeItem("logInCalled");
      dispatch(setLoading(false));
      if (user.success) {
        toast.success(user.message, {
          icon: ({ theme, type }) => (
            <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]">
              <img src={SuccessIcon} alt="success icon" />
            </div>
          ),
        });
        localStorage.setItem("accessToken", user?.profile?.token);
        location.pathname = "/";
      } else {
        // toast.error(user.message);
        setInvalidAuth(true);
      }
    }
  }, [user]);

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen px-5 sm:px-0"
      style={{
        background: "linear-gradient(129.77deg, #2C3345 26.01%, #323045 84.1%)",
      }}
    >
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center justify-center gap-10"
      >
        <div className="flex flex-col items-center gap-3">
          <div className="flex items-center gap-3">
            <p className="text-[32px] leading-[20px] font-semibold text-white">
              Welcome to
            </p>
            <img src={LogoImage} className="w-[200px]" alt="logo_img" />
          </div>
          <p className="text-secondary">
            Sign in using your company credentials
          </p>
        </div>
        <div className="flex flex-col items-center gap-8 min-w-[424px] w-[28%] bg-[#363D50] rounded-[32px] px-8 py-10">
          <div className="relative w-full">
            <input
              type="text"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className={`w-full !bg-transparent rounded-2xl	border border-solid pl-4 pr-9 py-3 text-base font-normal text-white placeholder:text-base placeholder:font-normal placeholder:text-[#A5AEBC] focus:border-white focus:outline-none focus:ring-0 focus:shadow-none ${
                inValidAuth ? "border-[#F25757]" : "border-[#596073]"
              }`}
              placeholder="Email"
              autoComplete="true"
            />
            {inValidAuth && (
              <div className="absolute inset-y-0 right-[16px] flex items-center justify-center">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 10.3334C7.18888 10.3334 7.34733 10.2694 7.47533 10.1414C7.60333 10.0134 7.66711 9.85515 7.66666 9.66671C7.66622 9.47826 7.60222 9.32004 7.47466 9.19204C7.34711 9.06404 7.18888 9.00004 7 9.00004C6.81111 9.00004 6.65288 9.06404 6.52533 9.19204C6.39777 9.32004 6.33377 9.47826 6.33333 9.66671C6.33288 9.85515 6.39688 10.0136 6.52533 10.142C6.65377 10.2705 6.812 10.3343 7 10.3334ZM7 7.66671C7.18888 7.66671 7.34733 7.60271 7.47533 7.47471C7.60333 7.34671 7.66711 7.18849 7.66666 7.00004V4.33337C7.66666 4.14449 7.60266 3.98626 7.47466 3.85871C7.34666 3.73115 7.18844 3.66715 7 3.66671C6.81155 3.66626 6.65333 3.73026 6.52533 3.85871C6.39733 3.98715 6.33333 4.14537 6.33333 4.33337V7.00004C6.33333 7.18893 6.39733 7.34737 6.52533 7.47537C6.65333 7.60337 6.81155 7.66715 7 7.66671ZM7 13.6667C6.07777 13.6667 5.21111 13.4916 4.4 13.1414C3.58889 12.7912 2.88333 12.3163 2.28333 11.7167C1.68333 11.1172 1.20844 10.4116 0.858662 9.60004C0.508885 8.78849 0.333774 7.92182 0.333329 7.00004C0.332885 6.07826 0.507996 5.2116 0.858662 4.40004C1.20933 3.58849 1.68422 2.88293 2.28333 2.28337C2.88244 1.68382 3.588 1.20893 4.4 0.858707C5.212 0.508485 6.07866 0.333374 7 0.333374C7.92133 0.333374 8.788 0.508485 9.6 0.858707C10.412 1.20893 11.1176 1.68382 11.7167 2.28337C12.3158 2.88293 12.7909 3.58849 13.142 4.40004C13.4931 5.2116 13.668 6.07826 13.6667 7.00004C13.6653 7.92182 13.4902 8.78849 13.1413 9.60004C12.7924 10.4116 12.3176 11.1172 11.7167 11.7167C11.1158 12.3163 10.4102 12.7914 9.6 13.142C8.78977 13.4927 7.92311 13.6676 7 13.6667Z"
                    fill="#F25757"
                  />
                </svg>
              </div>
            )}
          </div>
          <div className="relative w-full">
            <input
              type="password"
              id="password"
              value={password}
              ref={passwordField}
              onChange={handlePasswordChange}
              className={`w-full !bg-transparent rounded-2xl	border border-solid pl-4 pr-[70px] py-3 text-base font-normal text-white placeholder:text-base placeholder:font-normal placeholder:text-[#A5AEBC] focus:border-white focus:outline-none focus:ring-0 focus:shadow-none ${
                inValidAuth ? "border-[#F25757]" : "border-[#596073]"
              }`}
              placeholder="Password"
              autoComplete="true"
            />
            {password && (
              <button
                type="button"
                onClick={handleShowPassword}
                className="absolute inset-y-0 right-[16px]"
              >
                {!showPassword && (
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 20 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 4.5C8.61929 4.5 7.5 5.61929 7.5 7C7.5 8.38071 8.61929 9.5 10 9.5C11.3807 9.5 12.5 8.38071 12.5 7C12.5 5.61929 11.3807 4.5 10 4.5ZM8.5 7C8.5 6.17157 9.17157 5.5 10 5.5C10.8284 5.5 11.5 6.17157 11.5 7C11.5 7.82843 10.8284 8.5 10 8.5C9.17157 8.5 8.5 7.82843 8.5 7Z"
                      fill="#939DAD"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 0.5C7.99972 0.5 6.14837 1.24366 4.59668 2.19288C3.04317 3.14321 1.76075 4.31738 0.888494 5.22298C-0.0794231 6.22789 -0.0678791 7.77249 0.905285 8.76784C1.78943 9.67215 3.08676 10.8482 4.64188 11.8008C6.19425 12.7517 8.03628 13.5 10 13.5C11.9638 13.5 13.8058 12.7517 15.3582 11.8008C16.9133 10.8482 18.2106 9.67215 19.0948 8.76784C20.0679 7.77249 20.0795 6.22789 19.1115 5.22298C18.2393 4.31738 16.9569 3.14321 15.4034 2.19288C13.8517 1.24366 12.0003 0.5 10 0.5ZM1.60874 5.9167C2.44585 5.04759 3.66215 3.93684 5.11852 3.04593C6.57671 2.1539 8.24638 1.5 10 1.5C11.7537 1.5 13.4233 2.1539 14.8815 3.04593C16.3379 3.93684 17.5542 5.04759 18.3913 5.9167C18.982 6.52995 18.9772 7.45764 18.3797 8.06875C17.5293 8.93853 16.2965 10.0533 14.8358 10.9481C13.3724 11.8445 11.7131 12.5 10 12.5C8.28695 12.5 6.62763 11.8445 5.16423 10.9481C3.70358 10.0533 2.47071 8.93853 1.62032 8.06875C1.02283 7.45764 1.01807 6.52995 1.60874 5.9167Z"
                      fill="#939DAD"
                    />
                  </svg>
                )}
                {showPassword && (
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.64645 0.646447C1.84171 0.451184 2.15829 0.451184 2.35355 0.646447L18.3536 16.6464C18.5488 16.8417 18.5488 17.1583 18.3536 17.3536C18.1583 17.5488 17.8417 17.5488 17.6464 17.3536L14.5528 14.2599C13.1854 14.9811 11.6352 15.5 9.99993 15.5C8.03619 15.5 6.19416 14.7517 4.64179 13.8008C3.08668 12.8482 1.78935 11.6721 0.905197 10.7678C-0.0680206 9.77244 -0.0792881 8.22764 0.888592 7.22278C1.74711 6.33148 3.00456 5.1783 4.528 4.2351L1.64645 1.35355C1.45118 1.15829 1.45118 0.841709 1.64645 0.646447ZM5.25585 4.96295C3.73961 5.86643 2.47306 7.01927 1.60882 7.91652C1.0181 8.52981 1.0228 9.4577 1.62023 10.0687C2.47062 10.9385 3.70349 12.0533 5.16414 12.9481C6.62754 13.8445 8.28686 14.5 9.99993 14.5C11.323 14.5 12.6145 14.1091 13.8097 13.5168L11.3787 11.0858C10.9835 11.3475 10.5092 11.5001 10 11.5001C8.61929 11.5001 7.5 10.3808 7.5 9.00011C7.5 8.49101 7.65262 8.01664 7.91429 7.6214L5.25585 4.96295ZM8.64613 8.35323L10.6469 10.354C10.4511 10.4477 10.2319 10.5001 10 10.5001C9.17157 10.5001 8.5 9.82854 8.5 9.00011C8.5 8.76816 8.55239 8.54897 8.64613 8.35323Z"
                      fill="#939DAD"
                    />
                    <path
                      d="M9.05642 3.56181C9.36768 3.52142 9.6824 3.5 9.99993 3.5C11.7536 3.5 13.4232 4.1539 14.8814 5.04593C16.3378 5.93684 17.5541 7.04759 18.3912 7.9167C18.9817 8.52975 18.9767 9.45813 18.379 10.0694C18.027 10.4294 17.6083 10.8326 17.1339 11.2453C16.9256 11.4266 16.9037 11.7424 17.0849 11.9508C17.2662 12.1591 17.582 12.181 17.7903 11.9997C18.2878 11.5669 18.7261 11.1448 19.094 10.7685C20.067 9.77338 20.0796 8.22809 19.1115 7.22298C18.2392 6.31738 16.9568 5.14321 15.4033 4.19288C13.8516 3.24366 12.0002 2.5 9.99993 2.5C9.63736 2.5 9.27961 2.52446 8.92771 2.57013C8.65386 2.60567 8.46068 2.85648 8.49622 3.13033C8.53177 3.40417 8.78257 3.59736 9.05642 3.56181Z"
                      fill="#939DAD"
                    />
                  </svg>
                )}
              </button>
            )}
            {inValidAuth && (
              <div className="absolute inset-y-0 right-[48px] flex items-center justify-center">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 10.3334C7.18888 10.3334 7.34733 10.2694 7.47533 10.1414C7.60333 10.0134 7.66711 9.85515 7.66666 9.66671C7.66622 9.47826 7.60222 9.32004 7.47466 9.19204C7.34711 9.06404 7.18888 9.00004 7 9.00004C6.81111 9.00004 6.65288 9.06404 6.52533 9.19204C6.39777 9.32004 6.33377 9.47826 6.33333 9.66671C6.33288 9.85515 6.39688 10.0136 6.52533 10.142C6.65377 10.2705 6.812 10.3343 7 10.3334ZM7 7.66671C7.18888 7.66671 7.34733 7.60271 7.47533 7.47471C7.60333 7.34671 7.66711 7.18849 7.66666 7.00004V4.33337C7.66666 4.14449 7.60266 3.98626 7.47466 3.85871C7.34666 3.73115 7.18844 3.66715 7 3.66671C6.81155 3.66626 6.65333 3.73026 6.52533 3.85871C6.39733 3.98715 6.33333 4.14537 6.33333 4.33337V7.00004C6.33333 7.18893 6.39733 7.34737 6.52533 7.47537C6.65333 7.60337 6.81155 7.66715 7 7.66671ZM7 13.6667C6.07777 13.6667 5.21111 13.4916 4.4 13.1414C3.58889 12.7912 2.88333 12.3163 2.28333 11.7167C1.68333 11.1172 1.20844 10.4116 0.858662 9.60004C0.508885 8.78849 0.333774 7.92182 0.333329 7.00004C0.332885 6.07826 0.507996 5.2116 0.858662 4.40004C1.20933 3.58849 1.68422 2.88293 2.28333 2.28337C2.88244 1.68382 3.588 1.20893 4.4 0.858707C5.212 0.508485 6.07866 0.333374 7 0.333374C7.92133 0.333374 8.788 0.508485 9.6 0.858707C10.412 1.20893 11.1176 1.68382 11.7167 2.28337C12.3158 2.88293 12.7909 3.58849 13.142 4.40004C13.4931 5.2116 13.668 6.07826 13.6667 7.00004C13.6653 7.92182 13.4902 8.78849 13.1413 9.60004C12.7924 10.4116 12.3176 11.1172 11.7167 11.7167C11.1158 12.3163 10.4102 12.7914 9.6 13.142C8.78977 13.4927 7.92311 13.6676 7 13.6667Z"
                    fill="#F25757"
                  />
                </svg>
              </div>
            )}
          </div>
          <div className="w-full flex flex-col items-center gap-4">
            <button
              type="submit"
              className="w-full rounded-2xl bg-[#FFD74A] backdrop-blur-[16px] px-[22px] py-[14px] text-sm leading-[21px] font-bold text-black focus:outline-none active:!bg-[#FDC20D] btnHover"
            >
              Sign in
            </button>            
            <Link
              to="/password-reset"
              className="btnHover text-secondary !text-[#FFE24B] underline py-1.5 active:!text-[#FDC20D]"
            >
              Forgot password?
            </Link>
          </div>
          {errorMessage && (
            <p className="text-sm font-medium text-red-400">{errorMessage}</p>
          )}
          {inValidAuth && (
            <p className="text-sm font-medium text-red-400">Incorrect email or password. Please try again.</p>
          )}
        </div>
      </form>
    </div>
  );
}

export default Login;
