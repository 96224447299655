import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  selectUser,
} from "../../../../../redux/slices/userSlice";

const Account = () => {
  const dispatch = useDispatch();
  const State = useSelector(selectUser);
  const { userDetails } = State;  

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  return (
    <div className="p-8 flex flex-col gap-8">
      <p className="text-2xl font-bold text-white">Account</p>
      <div className="rounded-[32px] border border-solid border-[#596073] p-6 flex flex-col gap-4">
        <div>
          <p className="text-sm font-bold text-white">Display name</p>
          <span className="text-sm font-normal text-white">
            {userDetails?.user?.name}
          </span>
        </div>
      </div>
      <div className="rounded-[32px] border border-solid border-[#596073] p-6 flex flex-col gap-4">
        <div>
          <p className="text-sm font-bold text-white">Email</p>
          <span className="text-sm font-normal text-white">
            {userDetails?.user?.email}
          </span>
        </div>        
      </div>
      <div className="rounded-[32px] border border-solid border-[#596073] p-6 flex flex-col gap-4">
        <div>
          <p className="text-sm font-bold text-white">Thryft Role</p>
          <span className="text-sm font-normal text-white capitalize">
            {userDetails?.user?.role}
          </span>
        </div>        
      </div>
    </div>
  );
};

export default Account;
