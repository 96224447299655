import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ResetPasswordRequest,
  selectUser,
  setLoading,
  removeEmailSent,
} from "../../redux/slices/userSlice";
import { toast } from "react-toastify";
import SuccessIcon from "../../assets/icons/success-check.svg";

function PasswordReset() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();

  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    localStorage.setItem("resetUserEmail", event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (email === "") {
      setErrorMessage("* Email is required");
    } else {
      // Perform Password Reset logic here
      dispatch(setLoading(true));
      localStorage.setItem("resetPassCalled", "true");
      dispatch(
        ResetPasswordRequest({
          email: email,
        })
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("resetPassCalled") === "true" && !user.isLoading) {
      localStorage.removeItem("resetPassCalled");
      dispatch(setLoading(false));
      if (user.success) {
        toast.success(user.message, {
          icon: ({ theme, type }) => (
            <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#6BE2613D]">
              <img src={SuccessIcon} alt="success icon" />
            </div>
          ),
        });
        location.pathname = "/login";
      } else {
        toast.error(user.message);
      }
    }
    if (user.emailSent) {
      dispatch(removeEmailSent);
      setTimeout(() => {
        window.location.href = "/check-reset-password";
      }, 500);
    }
  }, [user]);

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen px-5 sm:px-0"
      style={{
        background: "linear-gradient(129.77deg, #2C3345 26.01%, #323045 84.1%)",
      }}
    >
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center justify-center gap-10"
      >
        <div className="flex flex-col items-center gap-4">
          <p className="text-[32px] leading-[20px] font-semibold text-white py-[13px]">
            Forgot password?
          </p>
          <p className="text-secondary">
            No worries, we’ll send you reset instructions
          </p>
        </div>
        <div className="flex flex-col items-center gap-8 min-w-[424px] w-[28%] bg-[#363D50] rounded-[32px] px-8 py-10">
          <input
            type="text"
            id="email"
            value={email}
            onChange={handleEmailChange}
            className={`w-full !bg-transparent rounded-2xl	border border-solid pl-4 pr-9 py-3 text-base font-normal text-white placeholder:text-base placeholder:font-normal placeholder:text-[#A5AEBC] focus:border-white focus:outline-none focus:ring-0 focus:shadow-none ${
              errorMessage ? "border-[#F25757]" : "border-[#596073]"
            }`}
            placeholder="Email"
            autoComplete="true"
          />
          <div className="w-full flex flex-col items-center gap-4">
            <button
              type="submit"
              className="w-full rounded-2xl bg-[#FFD74A] backdrop-blur-[16px] px-[22px] py-[14px] text-sm leading-[21px] font-bold text-black focus:outline-none active:!bg-[#FDC20D] btnHover"
            >
              Reset password
            </button>
            <Link to="/login" className="flex items-center gap-2 py-1.5 btnHover">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.2771 4.29434C16.7457 4.76297 16.7457 5.52277 16.2771 5.9914L10.4828 11.7857L16.2771 17.5801C16.7457 18.0487 16.7457 18.8085 16.2771 19.2771C15.8085 19.7457 15.0487 19.7457 14.58 19.2771L8.78571 13.4828C7.84845 12.5455 7.84845 11.0259 8.78571 10.0887L14.58 4.29434C15.0487 3.82571 15.8085 3.82571 16.2771 4.29434Z"
                  fill="#FFD74A"
                />
              </svg>
              <span className="text-sm font-bold leading-[21px] text-[#FFD74A] active:!text-[#FDC20D]">
                Back to log in
              </span>
            </Link>
          </div>
          {errorMessage && (
            <p className="text-sm font-medium text-red-400">{errorMessage}</p>
          )}
        </div>
      </form>
    </div>
  );
}

export default PasswordReset;
