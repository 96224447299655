

export const heading = [
    "Expense",
    "Expense category",
    "Cost",
    "Reason",
    "Transaction date",
    "Receipt",
    ""
]

export const tableData = [
    {
        id: 0,
        expense: "Expense title1",
        category: "Airfare",
        cost: "20023",
        transaction_date: "07/08/2023",
        expensed_date: "05/12/2023",
        receipt: true,
    },
    {
        id: 1,
        expense: "Expense title2",
        category: "Meals",
        cost: "15624",
        transaction_date: "24/05/2023",
        expensed_date: "31/08/2023",
        receipt: true,
    },
    {
        id: 2,
        expense: "Expense title3",
        category: "Meals",
        cost: "758",
        transaction_date: "17/04/2023",
        expensed_date: "16/01/2023",
        receipt: false,
    },    
]