import React from "react";

function Checkbox({ children, name, id, className, onClick, checked }) {
  return (
    <input
      onClick={onClick}
      name={name}
      id={name}
      checked={checked}
      className={`border border-[#A5AEBC] rounded-[4px] text-white !bg-transparent ${className}`}
      type="checkbox"
    />
  );
}

export default Checkbox;
